.wrapper.footer-header {
	@include media-breakpoint-down(xs) { .client-logo-container { width: 100%; text-align: center; } 
	}
}

#wrapper-footer {
	padding-bottom: 0;
	padding-top: 0;
	background-color: $black;
	color: $white;

	.footer__tagline__container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include media-breakpoint-down(sm) {
			justify-content: center;
			flex-flow: column wrap;
			text-align: center;
			margin-bottom: 3rem;
		}

		.social-links {
			margin-bottom: 0;
		}
	}

	.footer__tagline {
		position: relative;
		z-index: 10;
		padding-top: 1rem;
		padding-bottom: 1rem;
		&:before {
			content: "";
			background: $black;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 200px;
			z-index: -1;
		}
		h6 {
			@include fontSize(16px);
			margin-bottom: 0.25rem;
			color: $white;
		}
		strong {
			@include media-breakpoint-down(md) {
				display: block;
			}
		}
		p {
			@include media-breakpoint-down(sm) {
				@include fontSize(20px);
			}
			@include fontSize(28px);
			font-family: $font-regular;
			margin-bottom: 0;
		}
	}

	.form--newsletter {
		@include media-breakpoint-only(md) {
			text-align: center;
		}
		@include media-breakpoint-down(md) {
			margin-top: 2rem;
		}

		@include media-breakpoint-up(lg) {
			margin-left: auto;
			margin-right: auto;
			margin-top: 3rem;
			max-width: 504px;
		}

		.gform_button {
			min-width: unset;
			height: 50px;
			width: 100%;
		}
		small {
			@include fontSize(12px);
			color: $white;
			a, a:visited {
				color: $white;
				text-decoration: underline;
				border: 0 none;
			}
			a:hover {
				color: $primary;
				text-decoration: none;
			}
		}
	}

	.container--accreditation-badges {
		margin-left: auto;
		@include media-breakpoint-down(sm) { margin: 2rem auto 0; }
		img {
			display: block;
			margin: 0 auto;
		}
	}
}

.footer__footer {
	padding: 1.5rem 0 3rem;
	background: #212121;
	position: relative;
	&:after {
		@include media-breakpoint-up(lg) {
			content: "";
			position: absolute;
			right: 0;
			top: -344px;
			width: 410px;
			height: 343px;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='410' height='343' fill='none'%3E%3Cg fill='%23F39200' clip-path='url(%23a)'%3E%3Cpath d='M572-41-684 805l-358 78L558 751l14-792Z' opacity='.3'/%3E%3Cpath d='M548-163-46 541l-996 246L558 655l-10-818Z' opacity='.3'/%3E%3Cpath d='M556-431 264 543-946 787 654 655 556-431Z' opacity='.3'/%3E%3Cpath d='m558 163-1600 636v43H558V163Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h410v343H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");	
		}
	}
	@include fontSize(12px);
	a, a:visited {
		color: $white;
		border: 0 none;
	}
	a:hover {
		color: $primary;
	}
	span.separator {
		padding: 0 0.25rem;
	}
	svg {
		height: 16px;
		width: auto;
	}
}

.footer__contact {
	@include media-breakpoint-only(md) {
		display: flex;
		justify-content: space-between;
		flex-flow: column;
	}
}

.footer__accreditations {
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 4.5rem 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
	@include media-breakpoint-down(md) {
		flex-flow: row wrap;
		justify-content: center;
		padding: 2rem 0;
	}
}

.footer__accreditations__item {
	text-align: center;
	display: flex;
	justify-content: center;
	
	@include media-breakpoint-down(sm) {
		padding: 1rem;
		width: 50%;
		margin-bottom: 1rem;
	}
	@include media-breakpoint-down(md) {
		width: 33.333%;
		margin-bottom: 2rem;
	}
	
	@include media-breakpoint-up(lg) {
		max-width: 130px;
		flex-grow: 1;
	}
	img {
		width: auto;
		display: block;
		max-height: 90px;
		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}
	}
}

.footer__links {
	@include media-breakpoint-down(sm) {
		padding-left: 0;
		padding-right: 0;
	}
	@include media-breakpoint-only(md) {
		display: flex;
		.menu-footer-menu-container {
			width: 90%;
		}
		.social-links {
			justify-content: center;
			flex-flow: column;
			margin-left: auto;
		}
	}
}

.footer__legals {
	@include media-breakpoint-down(sm) {
		text-align: center;
		padding-left: 0;
	}
	@include media-breakpoint-down(md) {
		display: flex;
		flex-flow: column wrap;
		width: 100%;
		a {
			display: block;
			margin-bottom: .5rem;
		}
		.separator {
			display: none;
		}
		.copyright {
			display: block;
			margin-bottom: 1rem;
		}
	}
	@include media-breakpoint-only(md) {
		padding-left: 2rem;
	}
	@include media-breakpoint-only(lg) {
		padding-left: 2rem;
	}
	@include media-breakpoint-up(xl) {
		padding-left: 3rem;
	}
	a, p, span {
		color: $white;
	}
}

.footer__credit {
	@include media-breakpoint-down(sm) {
		padding-left: 1rem;
		text-align: center;
		width: 100%;
	}
	@include media-breakpoint-down(md) {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	@include media-breakpoint-only(md) {
		padding-left: 2rem;
	}
	@include media-breakpoint-up(lg) {
		margin-left: auto;
		padding-right: 1rem;
	}
}

.footer-menu {
	display: flex;
	flex-flow: row wrap;
	list-style: none;
	gap: 1rem;

	@include media-breakpoint-down(sm) {
		flex-flow: column wrap;
		gap: .25rem;
		margin-bottom: 3rem !important;
	}
	@include media-breakpoint-down(md) {
		padding-top: .25rem;
		margin-bottom: 2rem;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 4.5rem;
		margin-bottom: 4.5rem;
	}

	li {
		transition: all .2s ease-in-out;
		padding-left: 0;
		flex-basis: calc(33.333333% - .7em);
		@include media-breakpoint-down(md) {
			flex-basis: calc(49% - .7em);
		}
		&:last-of-type {
			box-shadow: none;
		}

		&:hover {
			box-shadow: 0 1px 0 0 $primary;
		}

		a {
			display: block;
			position: relative;
			padding: 1rem 1.25rem;
			background: rgba(255, 255, 255, 0.04);
			border-radius: 8px;
			font-weight: 700;

			@include fontSize(24px);

			@include media-breakpoint-down(sm) {
				@include fontSize(18px);
			}

			&:hover {
				color: $primary-light;
				text-decoration: none;
				&:before {
					width: 100%;
				}
				&:after {
					transform: translate(10px, -50%);
				}
			}

			&:before {
				content: "";
				position: absolute;
				background: $white;
				left: 0;
				top: 0;
				width: 0;
				height: 100%;
				opacity: .025;
				transition: all .2s ease-in-out;
			}

			&:after {
				content: "";
				position: absolute;
				right: 1.5rem;
				top: 50%;
				transform: translate(0, -50%);
				width: 21px;
				height: 13px;
				background-repeat: no-repeat;
				transition: all .2s ease-in-out;
				background-image: url("data:image/svg+xml,%3Csvg width='21' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.578.188l-.375.328a.635.635 0 000 .796l3.938 3.891H.563A.57.57 0 000 5.766v.468c0 .329.234.563.563.563H18.14l-3.938 3.937a.635.635 0 000 .797l.375.328c.188.235.563.235.797 0l5.438-5.437a.54.54 0 000-.797L15.375.187c-.234-.234-.61-.234-.797 0z' fill='%23fff'/%3E%3C/svg%3E");
			}
		}

		&:before {
			display: none;
		}
	}
}

.footer-crafted {
	background-color: $white;
	text-align:center;
	padding: 2rem 0;
	color: $black;
	@include fontSize(14px);
	img {
		margin-top: -3px;
	}
	a {
		border: 0 none;
	}
}

.footer__testimonial {
	display: flex;
	align-items: center;
	padding-top: 3rem;
	padding-bottom: 5rem;
	margin-left: auto;
	margin-right: auto;
	@include media-breakpoint-up(md) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	@include media-breakpoint-down(md) {
		flex-flow: column;
		align-items: flex-start;
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}
.footer__testimonial__source {
	min-width: 260px;
	display: block;
	@include media-breakpoint-down(md) {
		margin-left: 1.5rem;
		width: 100%;
	}
	@include media-breakpoint-up(lg) {
		padding-left: 3%;
		flex-shrink: 0;
	}
}

.footer__testimonial__source__image {
	max-width: 150px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.footer__testimonial__content {
	@include media-breakpoint-up(lg) {
		max-width: 824px;
		padding-right: 1.5rem;
	}
	.blockquote-large {
		@include media-breakpoint-down(md) {
			margin-bottom: 0;
			margin-top: 0;
		}
		p {
			font-weight: 600;
			font-size: 1.5rem;
			line-height: 36px;
			@include media-breakpoint-down(md) {
				font-size: 1.25rem;
				line-height: 2rem;
				margin-bottom: 0;
			}
			&:before {
				color: $primary;
			}
		}
	}
	.blockquote {
		margin-bottom: 0;
		@include media-breakpoint-down(md) {
			margin-top: 1rem;
		}
		p {
			font-size: 1rem;
			line-height: 1.625rem;
			margin-bottom: 0;
			&:before {
				display: none;
			}
		}
	}
}
.footer__testimonial__source {
	@include media-breakpoint-down(md) {
		order: 2;
	}
	.name, .position, .cite {
		display: block;
		font-weight: $fw-semi;
		color: $black;
	}
	.name {
		font-size: 1.5rem;
		display: block;
	}
	.position, .cite {
		display: block;
		font-style: normal;
		text-transform: uppercase;
		font-size: 0.75rem;
	}
}

// Footer addresses

.footer__addresses {
	display: flex;
	flex-flow: row wrap;
	gap: 1.5rem;
	@include media-breakpoint-down(sm) {
		flex-direction: column;
		justify-content: center;
	}
}
.footer__addresses__item {
	flex: 1;
	min-width: 32%;
	max-width: 32%;
	@include media-breakpoint-down(sm) {
		text-align: center;
		min-width: 100%;
	}
	address {
		@include fontSize(15px);
	}
}
.footer__addresses__item__directions {
	display: flex;
	align-items: center;
	gap: .5rem;
	margin-bottom: .5rem;
	@include media-breakpoint-down(sm) {
		text-align: center;
		justify-content: center;
	}
	h3 {
		color: $white;
		margin-bottom: 0;
		@include fontSize(20px);
	}
	.btn {
		font-size: 0.625rem;
		line-height: 1.5rem;
		padding: 0 .5rem;
		text-transform: uppercase;
		border-radius: 24px;
		border: 1px solid transparent;
		&:hover {
			color: $white;
			border: 1px solid $orange;
			background: transparent;
			animation: pulse 0.75s ease-in-out;
		}
	}
}

// Footer contact options

.footer__contact__options {
	display: flex;
	align-items: center;
	margin-bottom: 3rem;
	gap: 2.5rem;
	@include media-breakpoint-down(md) {
		justify-content: center;
		flex-flow: column;
		gap: 1rem;
	}
	h2 {
		color: $white;
		margin-bottom: 0;
		padding-right: 2.5rem;
		@include media-breakpoint-down(md) {
			padding-right: 0;
		}
		@include fontSize(32px);
		span {
			display: inline-block;
			margin-right: .5rem;
		}
	}
}

.footer__contact__options__item {
	a {
		text-decoration: underline;
		@include fontSize(18px);
		&:hover {
			color: $white;
			text-decoration: none;
		}
	}
}

.footer__top {
	position: relative;
}