.hero--service {
    position: relative;
    z-index: 0;
    padding: 30rem 0 4rem 0;
    @include media-breakpoint-down(md) {
        background: $black;
        flex-flow: column;
    }
    @include media-breakpoint-up(lg) {
        display: flex;
        margin-top: -150px;
        max-height: 840px;
        min-height: 840px;
        overflow: hidden;
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 213px;
            left: 0px;
            top: 0px;
            z-index: 1;
            background: linear-gradient(180deg, #000000 0%, rgba(21, 21, 21, 0) 100%);
        }
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 454px;
            left: 0px;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
        }
    }
    @include media-breakpoint-down(lg) {
        display: flex;
        padding: 0 0 3rem 0;
    }
    @include media-breakpoint-up(xl) {
        margin-top: -150px;
    }
    .container {
        position: relative;
        z-index: 10;
        margin-top: auto;
    }
    .btn {
        min-width: 304px;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}

.hero--service__image {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include media-breakpoint-down(md) {
            width: 100%;
            height: auto;
        }
        @include media-breakpoint-up(lg) {
            position: absolute;
        } 
    }
    @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 3rem;
    }
    @include media-breakpoint-up(lg) {
        animation: zoomies 15s ease-in-out infinite alternate;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        max-height: 840px;
        min-height: 840px;
        overflow: hidden;
    }
}

.hero--service__title {
    color: #fff;
    max-width: 621px;
    display: flex;
    align-items: center;
    div {
        flex-shrink: 0;
    }
    svg {
        max-width: 88px;
        width: 100%;
        height: auto;
        margin-right: 1.5rem;
        @include media-breakpoint-down(md) {
            max-width: 64px;
            margin-right: .5rem;
        }
        path {
            fill: $white;
        }
        circle {
            stroke: $white;
        }
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
    } 
}
.hero--service__resources {
    h4 {
        text-transform: uppercase;
        color: #fff;
        letter-spacing: 0.4em;
        text-align: right;
        font-weight: 600;
        font-size: 14px;
        @include media-breakpoint-down(md) {
            text-align: left;
            margin-top: 3rem;
        }
    }
}

.service__intro {
    padding-bottom: 1.5rem;
    @include media-breakpoint-only(lg) {
        max-width: 580px;
        padding-right: 2.5rem;
    }
    @include media-breakpoint-up(xl) {
        max-width: 712px;
        padding-right: 3rem;
    }
    h2 {
        font-size: 1.5rem;
        line-height: 2.25rem;
        margin-bottom: 2rem;
    }
    p {
        font-size: 1rem;
    }
    @include media-breakpoint-up(md) {
        margin-bottom: 3rem;
        padding-bottom: 2rem;
        position: relative;
        &:after {
            content:"";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(90deg, #F39200 60.49%, #F0F2F7 100%);
        }
    }
}
.service__intro__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include media-breakpoint-down(md) {
        flex-flow: column;
    }
}

.service__intro__aside {
    max-width: 530px;
}

.service__contact {
    width: 100%;
    background: $grey-lightest;
    border-radius: $border-radius-lg;
    padding: 2rem;
    @include media-breakpoint-down(sm) {
        padding: 2rem 1rem;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
    }
    h3 {
        @include media-breakpoint-up(lg) {
            line-height: 2rem;
        }
    }
}
.service__social {
    width: 100%;
    background: $black;
    border-radius: $border-radius-lg;
    padding: 2rem;
    margin-top: 1rem;
    @include media-breakpoint-down(sm) {
        padding: 2rem 1rem;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
    }
    .social-links {
        margin-bottom: 0;
    }
    h3, p {
        color: $white;
        margin-bottom: 0;
    }
    h3 {
        margin-right: 1rem;
        @include media-breakpoint-up(lg) {
            line-height: 2rem;
        }
    }
}

.service__testimonial {
    @include media-breakpoint-between(md, lg) {
        margin-left: auto;
        margin-right: auto;
    }
    .blockquote {
        max-width: 530px;
        margin-top: 0;
        p {
            font-size: 1rem !important;
            line-height: 1.625rem;
        }
    }
}

.service__list {
    display: flex;
    flex-flow: row wrap;
    @include media-breakpoint-down(md) { 
        margin-bottom: 2.5rem;
    }
    @include media-breakpoint-down(sm) {
        flex-flow: column wrap;
    }
    &--vertical {
        flex-flow: row wrap;
    }
}
.service__list__wrap {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .page-template-careers & {
        background-color: $grey-lightest;
        position: relative;
        padding-top: 6.5rem;
        padding-bottom: 6.5rem;
    }
}
.service__list__title {
    color: $primary;
    font-size: 2rem !important;
    line-height: 3rem;

    .page-template-careers & {
        color: $black;
        font-size: 3.5rem !important;
        line-height: 4.5rem;
        margin-bottom: 2.5rem;

        @include media-breakpoint-down(md) { 
            font-size: 2.5rem !important;
            line-height: 3rem;
        }
    }
}
.service__list__item {
    flex-basis: 33%;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-right: 1.5rem;

    .service__list--vertical & {
        flex-basis: 33%;
        padding-right: 0;
        @include media-breakpoint-only(xs) {
            flex-basis: 100%;
        }
        @include media-breakpoint-between(sm, lg) {
            flex-basis: 50%;
        }
    }

    @include media-breakpoint-down(sm) {
        flex-basis: 100%;
    }
    @include media-breakpoint-between(md, lg) {
        flex-basis: 50%;
    }
    p {
        font-size: 1rem;
        line-height: 26px;
        padding-left: 1rem;
        margin-bottom: 0;
        font-weight: 600;
        .service__list--vertical & {
            padding-right: 1rem;
        }

        .page-template-careers & {
            font-weight: 400;
        }
    }
    &:before {
        content: "";
        display: inline-flex;
        width: 64px;
        height: 64px;
        flex-shrink: 0;
        position: static;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Crect width='64' height='64' rx='32' fill='%23EEF0F5'/%3E%3Cpath d='M40.36 24.313L27.233 37.438l-4.64-4.641a.635.635 0 00-.797 0l-.516.562c-.234.188-.234.563 0 .797l5.578 5.578c.188.188.563.188.75 0l14.063-14.062c.234-.235.234-.61 0-.797l-.516-.563a.635.635 0 00-.797 0z' fill='%23DC7200'/%3E%3C/svg%3E");
        @include media-breakpoint-down(sm) {
            width: 40px;
            height: 40px;
        }
        .service__list--vertical & {
            background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Crect width='64' height='64' rx='32' fill='%23ffffff'/%3E%3Cpath d='M40.36 24.313L27.233 37.438l-4.64-4.641a.635.635 0 00-.797 0l-.516.562c-.234.188-.234.563 0 .797l5.578 5.578c.188.188.563.188.75 0l14.063-14.062c.234-.235.234-.61 0-.797l-.516-.563a.635.635 0 00-.797 0z' fill='%23DC7200'/%3E%3C/svg%3E");
        }
    }

    .page-template-careers & {
        flex-basis: 50%;
    }
}
.service__image {
    position: relative;
    display: flex;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        margin-bottom: 3rem;
    }
    @include media-breakpoint-between(md, lg) {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 10rem;
        margin-bottom: 10rem;
    }
    img {
        width: 100%;
        max-width: 1257px;
        height: auto;
        @include media-breakpoint-down(sm) {
            border-radius: 0 !important;
        }
        @include media-breakpoint-up(md) {
            border-radius: 2rem !important;
        }
    }
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 900px;
        max-width: 997px;
        z-index: -1; 
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='997' height='900' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M564.527.01C494.706-.894 437.35 55.2 436.447 125.253V248.408c-.451 76.848-62.956 138.653-139.551 138.2H128.08C58.26 385.702.903 441.798 0 511.849v261.81c.271 70.051 57.175 126.6 126.996 126.328h305.387c69.82.997 127.176-55.099 128.17-125.15V651.59c.451-76.849 62.956-138.654 139.551-138.2H868.92c69.821.906 127.177-55.19 128.08-125.241V125.977C996.458 55.925 939.463-.533 869.643.011H564.527z' fill='%23F7F8FC'/%3E%3C/svg%3E");
        @include media-breakpoint-down(sm) {
            background-size: contain;
            width: 70%;
            height: 100%;
        }
    }
}
