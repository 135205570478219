.card--job {
    overflow: hidden;
    border-radius: $border-radius-lg;
    background: $grey-lightest;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    height: 100%;
    border: 0px;
    color: $black;
    &:hover {
        .card--job__body {
            background: $grey-lightest;
            &:before {
                left: 0;
            }
        }
    }
}

.card--job__header, .card--job__body {
    padding: 2rem;
    @include media-breakpoint-down(sm) {
        padding: 1.5rem 1rem;
    }
}

.card--job__body {
    background: $grey-light;
    margin-top: auto;
    position: relative;
    transition: .3s ease-in-out;
    p {
        margin-bottom: .5rem;
        @include fontSize(14px);
    }
    &:before {
        content: "";
        left: -100%;
        top: 0;
        position: absolute;
        height: 2px;
        width: 100%;
        background: linear-gradient(90deg, #F39200 60.49%, #F0F2F7 100%);
        transition: .3s ease-in-out;
    }
}
.card--job__link {
    text-decoration: underline;
    @include fontSize(14px);
}

.card--job__title {
    @include fontSize(32px);
    line-height: 2.5rem;
    margin-bottom: .5rem;
    letter-spacing: -0.03em;
    @include media-breakpoint-down(sm) {
        @include fontSize(20px);
        line-height: 1.75rem;
    }
}

.card--job__subtitle {
    text-transform: uppercase;
    color: $orange;
    @include fontSize(14px);
    font-weight: 600;
}

.card--job__meta {
    .meta-item {
        margin-bottom: .5rem;
        @include fontSize(14px);
    }
}

.no-jobs {
    background: $grey-lightest;
    padding: 3rem;
    border-radius: 1rem;
    @include media-breakpoint-down(sm) {
        padding: 1rem;
    }
    p {
        margin-bottom: 0;
        @include fontSize(18px);
    }
}