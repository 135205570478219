@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 146, 0, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(243, 146, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(243, 146, 0, 0);
    }
}