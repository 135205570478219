
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
	font-family: $font-regular;
	color: $black;
}

a {
	&:hover {
		text-decoration: none;
	}
}

/* Measuring, designing and engineering the UK's railways */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6  {
	font-family: $font-regular;
	font-weight: 600;
	margin: 0 0 1rem 0;
	line-height: 1.2;
	color: $black;
	letter-spacing: -0.05em;
}

h1, .h1{
	@include fontSize(60px);
	margin-bottom: 2.5rem;
	@include media-breakpoint-down(md) { @include fontSize(48px); }
	@include media-breakpoint-down(sm) { @include fontSize(32px); }
}
h1 strong {
	font-weight: 600;
}

h2, .h2{
  @include fontSize(48px);
  @include media-breakpoint-down(md) { @include fontSize(32px); }
  @include media-breakpoint-down(sm) { @include fontSize(24px); }
  line-height: 1;
  margin-bottom: 2rem;
  letter-spacing: -1px;
}

h3, .h3{
	@include fontSize(36px);
	color: $black;
	margin-bottom: 1.5rem;
	@include media-breakpoint-down(md) { @include fontSize(28px); }
	@include media-breakpoint-down(sm) { @include fontSize(20px); }
}

h4{
	@include fontSize(24px);
	color: $black;
	@include media-breakpoint-down(sm) { @include fontSize(18px); }
}

h5{
	font-family: $font-regular;
	@include fontSize(18px);
	@include media-breakpoint-down(sm) { @include fontSize(16px); }
	color: $black;
}
h6,.h6{
	@include fontSize(16px);
	font-family: $font-regular;
	font-weight: normal;
	text-transform: uppercase;
}

p {
	@include fontSize(16px);
	@include media-breakpoint-down(sm) { @include fontSize(16px); }
	margin: 0 0 1.5rem 0;
	line-height: 1.4;
	font-weight: 400;
}
p.lead{
	@include fontSize(24px);
	@include media-breakpoint-down(sm) {
		@include fontSize(20px);
		line-height: 2rem;
	}
	font-weight: 600;
	line-height: 2.4rem;
	margin-bottom: 2rem;
}
p.secondary, div.secondary p {
	@include fontSize(18px);
	@include media-breakpoint-down(sm) { 
		@include fontSize(17px); 
		line-height: 1.6rem;
	}
	font-weight: 400;
	line-height: 2rem;

	.page-template-recruitment & {
		@include media-breakpoint-down(sm) { 
			@include fontSize(18px); 
			line-height: 1.6rem;
		}
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 3rem;
		padding-bottom: 3rem;
		position: relative;
		&:after {
			content:"";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background: linear-gradient(90deg, #F39200 60.49%, #F0F2F7 100%);
		}
	}

	.page-template-careers & {
		@include media-breakpoint-down(sm) { 
			@include fontSize(18px); 
			line-height: 1.6rem;
		}
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 3rem;
		padding-bottom: 3rem;
		position: relative;
	}
}

.service__list__wrapper {
	.page-template-careers & {
		max-width: 60%;
		@include media-breakpoint-up(xxxl) { 
			max-width: 840px;
		}
		@include media-breakpoint-down(lg) { 
			max-width: 100%;
		}
	}
}

.service__list__wrap {
	img {
		.page-template-careers & {
			border-radius: 0;
			height: 100%;
			object-fit: cover;
		}
	}
	.shape {
		.page-template-careers & {
			position: absolute;
			bottom: 0;
			right: 0;
			pointer-events: none;
			overflow: hidden;

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
}

.careers_page_image {
	.page-template-careers & {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		right: 0;
		border-radius: 0;
		max-width: 35%;

		@include media-breakpoint-up(xxxl) { 
			max-width: 644px;
		}

		@include media-breakpoint-down(lg) { 
			max-width: 100%;
		}

		@include media-breakpoint-down(lg) { 
			position: relative;
		}
	}
}

.text-large {
	font-size: 150%;
	font-family: $font-regular;
	@include media-breakpoint-down(sm) { font-size: 112.5%; }
	strong {
		font-weight: 600;
	}
}
.text-heavy {
	font-family: $font-heavy;
}

ul {
	padding-left: 20px;
	@include fontSize(16px);
	margin-bottom: 1.5rem;
}

p:empty {
   display: none;
}

i.fal, i.fas {
	font-style: normal;
}

.site-main {

	h1, .h1 {
		@include fontSize(60px);
		font-family: $font-regular;
		font-weight: 600;
		color: $black;
		margin: 4rem 0 2.5rem;
		@include media-breakpoint-down(sm) { @include fontSize(30px); margin: 2rem 0; }
	}

	h2, .h2 {
		@include fontSize(30px);
		@include media-breakpoint-down(sm) { @include fontSize(24px); }
		font-family: $font-regular;
		font-weight: 600;
		margin-bottom: 1rem;
	}
	h3, .h3{
		@include fontSize(24px);
		@include media-breakpoint-down(sm) { @include fontSize(20px); }
		font-family: $font-regular;
		font-weight: 600;
		margin-bottom: 1rem;
	}
	h4, .h4 {
		@include fontSize(20px);
		@include media-breakpoint-down(sm) { @include fontSize(16px); }
		font-family: $font-regular;
		font-weight: 600;
		margin-bottom: 1rem;
	}
	p {
		@include fontSize(16px);
		margin-bottom: 2rem;
		line-height: 1.5;
		@include media-breakpoint-down(sm) { @include fontSize(16px); margin-bottom: 2rem; }
	}

	p.text-large {
		font-size: 150%;
		font-family: $font-regular;
		@include media-breakpoint-down(sm) { font-size: 112.5%; }
		strong {
			font-weight: 600;
		}
	}
}

.text-small {
	p {
		@include fontSize(14px);
	}
}

strong {
	font-weight: 800;
}

.stroked {
  margin: 0;
  font-size: 60px;
  letter-spacing: -2px;
  font-family: $font-heavy;
  font-weight: bold;
  svg {
    display: block;
  }
  
  text {
    fill: none;
    stroke: #DC7200;
    stroke-width: 1;
	.aos-animate & {
		stroke-dasharray: 250;
		stroke-dashoffset: 250;
		animation: dash 2s linear forwards;
	}
  }
}

@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}