.partnerships__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 3rem;
    @include media-breakpoint-down(md) {
        background: $grey-lightest;
        padding: 2rem;
        border-radius: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        &:nth-of-type(even) {
            .partnerships__item__content {
                order: 1;
                padding-left: 0;
                padding-right: 3rem;
            }
            .partnerships__item__image {
                order: 2;
                &:before {
                    content: "";
                    position: absolute;
                    right: -200%;
                    left: unset;
                    top: 50%;
                    width: 200%;
                    height: 2px;
                    background: $grey-lightest;
                }
            }
        }
    }
}

.partnerships__item__content {
    @include media-breakpoint-up(lg) {
        width: 55%;
        padding-left: 3rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.partnerships__item__image {
    img {
        max-width: 220px;
        @include media-breakpoint-down(md) {
            margin-bottom: 2rem;
            margin-top: 1rem;
        }
    }
    @include media-breakpoint-up(lg) {
        margin-right: auto;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.5rem;
        background: $grey-lightest;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: -200%;
            top: 50%;
            width: 200%;
            height: 2px;
            background: $grey-lightest;
        }
    }
}