.bg-img-right {
	position: relative;
    &:after {
    	content: "";
	    background-image: url('../img/portrait.jpg');
	    background-repeat: no-repeat;
	    background-size: cover;
	    position: absolute;
	    height: 100%;
	    width: 50%;
	    top: 0;
	    bottom: 0;
	    left: 50%;
	    right: 0;
	    z-index:0;
	    @include media-breakpoint-down(md) { left: 67%; width: 33.3%; }
	    @include media-breakpoint-down(sm) { display: none; }
	}
	.container {
		padding: 6rem 15px; // to make up for zero padding on the wrapper
		@include media-breakpoint-down(sm) { padding: 3rem 2rem; }
	}

}