.single-job__intro__wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 894px;
	width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 2rem;
    @include media-breakpoint-down(sm) {
        flex-flow: column;
        align-items: flex-start;
    }
}
.single-job__intro {
    margin-bottom: 0;
    margin-top: 4rem;
	display: flex;
    flex-flow: column;
	align-items: flex-start;
}
.single-job__share {
    display: flex;
    max-width: 894px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
.single-job__title {
	margin-bottom: 1rem;
	max-width: 621px;
	font-size: 3.75rem;
    @include media-breakpoint-down(sm) {
        font-size: 2.25rem;
    }
}
.single-job__subtitle {
    text-transform: uppercase;
    color: $orange;
    @include fontSize(14px);
    font-weight: 600;
}
.single-job__reference {
    @include fontSize(16px);
    font-weight: 600;
    letter-spacing: -.05em;
    margin-bottom: 0;
}
.single-job__meta {
    @include media-breakpoint-down(sm) {
        padding-top: 1.5rem;
    }
    @include media-breakpoint-up(md) {
        padding-left: 2rem;
    }
}
.single-job__meta-item {
    @include fontSize(14px);
    margin-bottom: .5rem;

    &:last-of-type {
        margin-bottom: 0;
    }
}
.single-job__main {
	width: 100%;
	max-width: 894px;
	margin: 0 auto;
	.text-large, .lead {
		max-width: 894px;
	}
	p, ul, ol, h2, h3, h4, h5 {
		max-width: 640px;
	}
}
.single-job__disclaimer {
    background: #F7F8FC;
    border-radius: 16px;
    padding: 3rem;
    margin-top: 3rem;
    max-width: 803px;
    @include media-breakpoint-down(sm) {
        padding: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        margin-left: -3rem;
    }
    h3 {
        @include fontSize(24px);
    }
    p {
        @include fontSize(14px);
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}