.contact-widget {
	h3 {
		@include fontSize(30px);
	}
	a.text-black, a.text-black:visited {
		border: 0 none;
		color: $black;
		&:hover {
			color: $primary;
		}
	}
}

.widget_recent_entries {


	.bg-grey--half {
	    position: relative;
	    padding: 2rem 0;
	}
	.bg-grey--half:after {
	    top: 0;
	    left: 20%;
	    width: 1000%;
	    width: 150%;
	    content: "";
	    background-color: $grey-lighter;
	    position: absolute;
	    height: 100%;
	    bottom: 0;
	    right: 0;
	    z-index: -1;
	    border-radius: $border-radius-lg;
	}




	.widget-title {
		margin-bottom: 0.75rem;
	}
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		li {
			padding: 1.5rem 0;
			border-bottom: 1px solid $black;
			&:last-child {
				border: 0 none;
			}
			&:before {
		      display: none;
		    }
			.post-date {
				text-transform: uppercase;
				@include fontSize(10px);
				display: block;
				font-family: $font-regular;
				margin-bottom: 0.75rem;
			}
			.post-title {
				@include fontSize(18px);
				margin-bottom: 0.75rem;
				font-weight: 600;
				a, a:visited {
					color: $black;
					border: 0 none;
				}
				a:hover {
					color: $primary;
				}
			}
			.post-body {
				@include fontSize(14px);
			}
		}
	}
}

.sidebar {
	padding-left: 1rem;
	@include media-breakpoint-down(md) { padding: 0;  }
}