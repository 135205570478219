//-------------------------------------------
// _tools.mixins.scss
//-------------------------------------------
//Clear fix
@mixin clearfix {
  &:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
}

// font size
@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

// Rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// Browser Prefixes
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}


/****************************
 HIGH PPI DISPLAY BACKGROUNDS
*****************************/

@mixin background-2x($path, $ext: "png", $w: auto, $h: auto, $pos: left top, $repeat: no-repeat) {

  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";
 
  background-image: url("#{$at1x_path}");
  background-size: $w $h;
  background-position: $pos;
  background-repeat: $repeat;
 
  @media all and (-webkit-min-device-pixel-ratio : 1.5),
  all and (-o-min-device-pixel-ratio: 3/2),
  all and (min--moz-device-pixel-ratio: 1.5),
  all and (min-device-pixel-ratio: 1.5) {
    background-image: url("#{$at2x_path}"); 
  }
}

/****
Button icons
****/

@mixin buttonIcon {
  position: relative;
  &:after {
      pointer-events: none;
      content: "";
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%);
      transition: all .2s ease-in-out;
      width: 21px;
      height: 14px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.59 1.164l-.375.328a.635.635 0 000 .797l3.937 3.89H.574a.57.57 0 00-.562.563v.469c0 .328.234.562.562.562h17.578l-3.937 3.938a.635.635 0 000 .797l.375.328c.187.234.562.234.797 0l5.437-5.438a.54.54 0 000-.796l-5.437-5.438c-.235-.234-.61-.234-.797 0z' fill='%23fff'/%3E%3C/svg%3E");
  }
  &:hover {
    &:after {
      top: 50%;
      transform: translate(5px, -50%);
    }
  }
}

@mixin buttonIconOrange {
  position: relative;
  &:after {
      pointer-events: none;
      content: "";
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%);
      width: 21px;
      height: 14px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.59 1.164l-.375.328a.635.635 0 000 .797l3.937 3.89H.574a.57.57 0 00-.562.563v.469c0 .328.234.562.562.562h17.578l-3.937 3.938a.635.635 0 000 .797l.375.328c.187.234.562.234.797 0l5.437-5.438a.54.54 0 000-.796l-5.437-5.438c-.235-.234-.61-.234-.797 0z' fill='%23DC7200'/%3E%3C/svg%3E");
  }
}

@mixin buttonIconContact {
  position: relative;
  &:after {
      pointer-events: none;
      content: "";
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21 0H3C1.312 0 0 1.36 0 3v13.5c0 1.688 1.313 3 3 3h4.5v3.938c0 .375.234.562.563.562.093 0 .187 0 .328-.094L14.25 19.5H21c1.64 0 3-1.313 3-3V3c0-1.64-1.36-3-3-3zm.75 16.5c0 .422-.375.75-.75.75h-7.5l-.61.469-3.14 2.343V17.25H3a.74.74 0 01-.75-.75V3c0-.375.328-.75.75-.75h18c.375 0 .75.375.75.75v13.5zM6 8.25c-.844 0-1.5.703-1.5 1.5 0 .844.656 1.5 1.5 1.5.797 0 1.5-.656 1.5-1.5 0-.797-.703-1.5-1.5-1.5zm6 0c-.844 0-1.5.703-1.5 1.5 0 .844.656 1.5 1.5 1.5.797 0 1.5-.656 1.5-1.5 0-.797-.703-1.5-1.5-1.5zm6 0c-.844 0-1.5.703-1.5 1.5 0 .844.656 1.5 1.5 1.5.797 0 1.5-.656 1.5-1.5 0-.797-.703-1.5-1.5-1.5z' fill='%23fff'/%3E%3C/svg%3E");
    }
}

@mixin buttonIconDown {
  position: relative;
  &:after {
      pointer-events: none;
      content: "";
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      transition: all .3s ease-in-out;
      width: 15px;
      height: 22px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 22'%3E%3Cpath d='M14.063 14.375l-.938-.938a.583.583 0 00-.797.047l-3.61 3.75V1.062c0-.28-.28-.562-.562-.562H6.844a.57.57 0 00-.563.563v16.171l-3.656-3.75a.583.583 0 00-.797-.046l-.937.937a.635.635 0 000 .797l6.187 6.187a.635.635 0 00.797 0l6.188-6.187a.54.54 0 000-.797z' fill='%23212121'/%3E%3C/svg%3E");
  }
  &:hover {
    &:after {
      transform: translateY(-35%);
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 22'%3E%3Cpath d='M14.063 14.375l-.938-.938a.583.583 0 00-.797.047l-3.61 3.75V1.062c0-.28-.28-.562-.562-.562H6.844a.57.57 0 00-.563.563v16.171l-3.656-3.75a.583.583 0 00-.797-.046l-.937.937a.635.635 0 000 .797l6.187 6.187a.635.635 0 00.797 0l6.188-6.187a.54.54 0 000-.797z' fill='%23ffffff'/%3E%3C/svg%3E");
    }
  }
}

@mixin buttonIconPin {
  position: relative;
  &:after {
      pointer-events: none;
      content: "";
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      height: 25px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 25'%3E%3Cpath d='M6.75 0C3 0 0 3.047 0 6.75a6.717 6.717 0 006 6.703v9.656l.516.797c.093.14.328.14.421 0l.563-.797v-9.656c3.328-.375 6-3.187 6-6.703C13.5 3.047 10.453 0 6.75 0zm0 12A5.241 5.241 0 011.5 6.75c0-2.86 2.344-5.25 5.25-5.25C9.61 1.5 12 3.89 12 6.75 12 9.656 9.61 12 6.75 12zm0-9A3.731 3.731 0 003 6.75c0 .422.328.75.75.75.375 0 .75-.328.75-.75A2.25 2.25 0 016.75 4.5c.375 0 .75-.328.75-.75 0-.375-.375-.75-.75-.75z' fill='%23fff'/%3E%3C/svg%3E");  }
}

