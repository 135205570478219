.services-list-container {
	.bg-grey--half {
	    position: relative;
	    padding: 2rem 0;
	}
	.bg-grey--half:after {
	    top: 0;
	    left: 20%;
	    width: 1000%;
	    width: 150%;
	    content: "";
	    background-color: $grey-lighter;
	    position: absolute;
	    height: 85%;
	    bottom: 0;
	    right: 0;
	    z-index: -1;
	    border-radius: $border-radius-lg;
	}
}

.services-list-sidebar {
	list-style-type: none;
	padding: 0;
	margin: 0;
	margin-bottom: 3rem;
	li {
		border-bottom: 1px solid $black;
		font-weight: 600;
		&:before {
	      display: none;
	    }
		a, a:visited {
			padding: 12px 0 11px 0;
			border-bottom: 0 none;
			color: $black;
			@include fontSize(16px);
			display: block;
		}
		a:hover {
			color: $primary;
		}
		&.active {
			a, a:visited {
				color: $primary;
			}
		}
	}
	li:last-child {
		border: 0 none;
	}

}


ul {
	margin: 0 0 2rem 0;
	padding: 0;
	list-style: none;
	@include fontSize(16px);
	@include media-breakpoint-down(sm) { @include fontSize(16px); }
	li {
		padding: 3px 0;
		padding-left: 25px;
		position: relative;
		&:before {
		    content: "";
			background: $primary;
		    width: 12px;
			height: 12px;
			border-radius: 50%;
		    margin-left: -25px;
		    margin-right: 1rem;
		    position: absolute;
		    top: 8px;
		}
	}
}

ul.check-list {
	margin: 0.5rem 0 0.5rem 0;
	padding: 0;
	list-style: none;
	li {
		@include fontSize (16px);
		padding: 2px 0;
		padding-left: 25px;
		position: relative;
		@include media-breakpoint-down(sm) { @include fontSize(16px); }
		&:before {
			color: $primary;
			font-family: 'Font Awesome 5 Pro';
		    content: "\f00c";
		    font-size: 1.1125em;
		    display: inline-block;
		    margin-left: -25px;
		    margin-right: 1rem;
		    position: absolute;
		    top: 4px;
		    font-size: 16px;
		    @include media-breakpoint-down(sm) { top: 0px; }
		}
	}
}

ul.icon-list {
	margin: 0;
	padding: 0;
	@include fontSize (16px);
	@include media-breakpoint-down(sm) { @include fontSize(16px); }
	list-style: none;
	li {
		padding: 4px 0;
		&:before {
	      display: none;
	    }
		i {
			font-size: 20px;
			margin-right: 0.5rem
		}
		a, a:visited {
			color: $grey;
			border: 0 none;
		}
		a:hover {
			color: $primary;
		}
		&:last-of-type {
			padding-bottom: 0;
		}
	}
}
ul.icon-list {
	.hero-related & {
		.btn {
			color: $white !important;
			i {
				color: $white !important;
			}
		}
	}
}


.bg-dark {
	ul.icon-list {
		list-style: none;
		li {
			color: $grey;
			i {
				color: $white;
			}
		}
	}
}

.wrapper-split-content {
	ul {
		margin: 0 0 2rem 0;
		padding: 0;
		list-style: none;
		@include fontSize(14px);
		@include media-breakpoint-down(sm) { @include fontSize(14px); }
		li {
			padding: 3px 0;
			padding-left: 15px;
			position: relative;
			&:before {
				color: $grey;
			    content: "\25A0";
			    font-size: 14px;
			    display: inline-block;
			    margin-left: -15px;
			    margin-right: 1rem;
			    position: absolute;
			    top: 1px;
			    @include media-breakpoint-down(xs) { top: 0px; }
			}
		}
	}
}



ol {
	@include fontSize(16px);
	padding: 0;
	margin: 0 0 2rem 1.25rem;
	list-style-type: none;
	@include media-breakpoint-down(sm) { @include fontSize(16px); margin-bottom: 2rem; }
	li {
	  	counter-increment: list;
	  	position: relative;
	  	&:before {
		  	color: $primary;
		  	content: counter(list) ".";
		  	left: -32px;
		  	position: absolute;
		  	text-align: right;
		  	width: 26px;
		}
	}

}
