.text-grey {
	color: $grey;
}
.text-primary {
	color: $primary;
}

.no-mg {
	margin: 0 !important;
}

// Background colours;
.bg-white {
  background-color: $white;
}
.bg-dark {
	background-color: $black !important;
	p {
		color: $grey-lighter;
	}
	h1, h2, h3, h4, h5, h6, ul {
		color: $white;
	}
	a {
		color: $primary;
		&:hover {
			color:$white;
			border-color: $white;
		}
	}
}

.bg-primary {
	background-color: $primary !important;
	color: $white;
	h3, h4, h5, h6 {
		color: $white;
	}
}
.bg-grey {
	background-color: $grey-light;
}
.bg-grey-lighter {
	background-color: $grey-lighter;
}
.bg-grey-lightest {
	background-color: $grey-lightest;
}
.bg-shape-lg {
	background-image: url('../img/svg/shape.svg'); 
    background-repeat: no-repeat;
    background-position: center top;
}
.bg-shape-repeat {
	background-image: url('../img/svg/shape_pattern_op5.svg'); 
	background-position: top center;
	background-size: 150%;
}

.pull-right {
	margin-left: auto;
}

.rotate-90 {
	@include rotate(90);
}

.rounded-box {
	padding: 2rem 1rem;
	border-radius: $border-radius-md;
	@include media-breakpoint-down(sm) {
		margin-left: 0;
		margin-right: 0;
	}
}

.contact-wrapper {
	.rounded-box {
		@include media-breakpoint-down(sm) {
			padding: 0;
		}
	}
}

.wrapper--padding {
	padding-top: 7.5rem;
	padding-bottom: 7.5rem;
	@include media-breakpoint-down(sm) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

.flex-align-end {
	align-items: flex-end;
}
.flex-align-center {
	align-items: center;
}

.flex-wrapper {
	display: flex;
}

.flex-wrap {
	flex-wrap: row wrap;
}

.d-grid {
	display: grid;
}

.ml-auto--md {
	@include media-breakpoint-up(md) {
		margin-left: auto;
	}
}

.ml-auto--lg {
	@include media-breakpoint-up(lg) {
		margin-left: auto;
	}
}

.no-margin {
	margin-bottom: 0;
}

.container {
	padding-left: 1rem;
	padding-right: 1rem;
}

// Bootstrap fixes


@include media-breakpoint-down(lg) {
	.container {
	  width: 100%;
	  max-width: none;
	  padding-left: 1rem;
	  padding-right: 1rem;

	  	.page-template-careers & {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
}

.navbar-expand-lg>.container,
.navbar-expand-lg>.container-fluid,
.navbar-expand-lg>.container-lg, 
.navbar-expand-lg>.container-md, 
.navbar-expand-lg>.container-sm, 
.navbar-expand-lg>.container-xl {
	@media (max-width: 991.98px) {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

.bg-corner-graphic {
	background-repeat: no-repeat;
	background-position: top left;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='823' height='615' fill='none'%3E%3Cg fill='%23CBCBCB' clip-path='url(%23a)' opacity='.7'%3E%3Cpath d='m433.326-29.981-846 1256.001-78 358 132-1600.002 792-14Z' opacity='.3'/%3E%3Cpath d='m555.326-5.981-704 594-246 996.001 132-1600.002 818 10Z' opacity='.3'/%3E%3Cpath d='m823.326-13.981-974 292-244 1210.001 132-1600.001 1086 98Z' opacity='.3'/%3E%3Cpath d='m229.326-15.981-636 1600.001h-43V-15.982h679Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M823 0v615H0V0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}