.blocks {
	.block {
		margin: 1rem 0 2rem 0;
		.block-body {
			min-height: 120px;
			padding-left: 1rem;
			padding-bottom: 2rem;
			h5 {
				@include fontSize(18px);
				font-family: $font-regular;
				margin-top: 0.75rem;
			}
			p {
				@include fontSize(14px);
			}
		}
	}
	div {
		&:last-child { .block { border: 0 none; } }
		&:nth-last-child(2) { .block { border: 0 none; } }
	}
}

.bg-dark {
	.block {
		h5 {
			color: $white;
		}
		p {
			color: $grey;
		}
	}
}


.block-recruitment {
	margin: 2rem 0.5rem 0 0;
	.block-header {
		padding-left: 0.5rem;
		h2.title {
			font-family: $font-regular;
			margin-bottom: 0.75rem;
			font-weight: 600;
			@include fontSize(42px);
			@include media-breakpoint-down(sm) { @include fontSize(36px); }
			color: $primary;
		}
		p.subtitle {
			font-family: $font-regular;
			text-transform: uppercase;
			margin-bottom: 1rem;
		}
	}
	.block-meta {
		padding: 1.25rem 0.75rem;
		background-color: $grey-light;
		border-radius: $border-radius-lg;
		font-family: $font-regular;
		@include fontSize(14px);
		.meta-item{
			padding-right: 3rem;
			@extend .flex-fill;
			@include media-breakpoint-down(sm) { margin-top: 0.125rem; margin-bottom: 0.125rem; padding-right: 1rem; }
		}
	}
	.block-body {
		padding: 1rem 0 1rem 0.5rem;
		@include fontSize(14px);
		.reference {
			font-weight: bold;
		}
		.full-description {
			margin-top: 2rem;
			padding: 4rem 3rem;
			background-color: $grey-lighter;
			border-radius: $border-radius-lg;
			margin-left: -0.5rem;
			margin-right: -0.5rem;
		}
		hr {
			margin: 2rem 0 1.75rem 0;
		}

		.read-more, .read-more-close {
		    cursor: pointer;
		    color: $primary;
		    font-family: $font-regular;
		    @include fontSize(10px);
		    text-transform: uppercase;
		    border: 0 none;
		    &:after {
		      content: "\f061";
		      font-family: 'Font Awesome 5 Pro';
		      position: relative;
		      top: 0;
		      left: 10px;
		      font-size: 10px;
		    }
		    &:hover {
		      color: $black;
		      &::after {
		        left: 12px;
		      }
		    }
		}
		.read-more-close {
			&:after {
		      content: "\f00d";
			}
		}
	}
}



.latest-news-block {
	img {
		margin-bottom: 3rem;
	}
	a, a:visited {
		color: $black;
		border: 0 none;
	}
	a:hover {
		img {
			@extend .shadow;
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
		}
		color: $primary;
	}
	.post-date {
		text-transform: uppercase;
		font-family: $font-regular;
		@include fontSize(10px);
		margin-bottom: 1rem;
	}
	.post-title {
		font-family: $font-regular;
		@include fontSize(24px);
	}
	.post-excerpt {
		@include fontSize(18px);
		p {
			line-height: 1.6;
		}
	}
	.heading-share {
		margin: 3rem 0;
		.share-items {
			text-align: left;
			li:first-child {
				a{
					padding-left: 0;
				}
			}
		}
	}
}						

.blocks--numbered {
	margin-top: 3.875rem;
	@include media-breakpoint-down(sm) {
		margin: 1rem 0;
	}
	div {
		&:last-of-type {
			.block {
				margin-bottom: 0;
			}
			.block-body {
				padding-bottom: 0;
			}
		}
	}
	.block {
		@include media-breakpoint-down(sm) {
			margin-top: 0.25rem;
		}

		display: flex;
		align-items: flex-start;
		
		.block-body {
			min-height: unset;
			padding-left: 0;
			padding-bottom: 0;
			@include media-breakpoint-down(sm) {
				min-height: 100px; 
				padding-left: 0.5rem; 
				padding-bottom: 2rem;
			}
			h5 {
				font-size: 1.5rem;
				line-height: 2rem;
				@include media-breakpoint-down(md) {
					margin-top: 0.5rem;
				}
				@include media-breakpoint-down(xs) {
					margin-top: 0.25rem;
				 }
			}
			p {
		    	margin-bottom: 0;
			}
		}
	}
}

.box {
	padding: 3.5rem 3rem;
	border-radius: $border-radius-lg;
	margin-bottom: 2.5rem;
	:last-child {
		margin-bottom: 0;
	}
	p, p:first-of-type {
		font-size: 18px !important;
		font-weight:normal !important;
		font-family: $font-regular !important;
	}
}

.bordered-box {
	border-top: 1px solid $primary;
	border-bottom: 1px solid $primary;
	margin: 2rem 0;
	padding: 2rem 0;
	&.text-primary {
		h2,h3,h4,p,li{
			color: $primary;
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
}