.related-article-cta {	
	padding: 2rem 15px;
	margin-left: -12.5%;
	position: relative;
	margin-bottom: 2rem;
	@include media-breakpoint-down(lg) { margin-left: 0; }
	&::after {
		border-top-right-radius: $border-radius-lg;
		border-bottom-right-radius: $border-radius-lg;
		content: "";
	    background-color: $grey-lighter;
	    position: absolute;
	    height: 100%;
	    width: 150%;
	    top: 0;
	    bottom: 0;
	    right: 0;
	    z-index: -1;
	}
	.label-container {
		height: 40px;
		@include rotate(90);
		label {
			white-space: nowrap;
			padding-right: 30px;
		}
		hr {
			width: 100%;
		}
		@include media-breakpoint-down(md) { @include rotate(0); }
		
	}
	.date {
		@include fontSize(10px);
		text-transform: uppercase;
	}
	h3 { 
		@include media-breakpoint-down(xs) { margin-top: 1rem; } 
	}
	h3:hover {
		color: $primary;
	}
	hr {
		margin: 1.5rem 0;
	}

	.col-lg-1 {
		@include media-breakpoint-up(md) { max-height: 195px; }
	}

}


.vertical-line {
    border-left: 1px solid $black;
    height: 25%;
    @include media-breakpoint-up(xl) { height: 25%;  }
    position: absolute;
    bottom: 0;
    left: 58%;
    @include media-breakpoint-down(md) { 
    	height:1px;
    	border: 0 none;
    	border-top: 1px solid $black;
    	left: 140px;
    	bottom: 27px;
    	position: relative;
    	width: 20%;
    }
}


///

.cta-block {
	padding: 3rem 3.75rem;
	@include media-breakpoint-down(sm) { padding: 3rem 2.5rem; }
	border-radius: $border-radius-lg;
	background-image: url('../img/svg/shape_pattern_op5.svg'); 
	background-position: top center;
	background-size: 300%;

	.cta-widget-title {
		color: $white;
		font-weight: 700;
		margin-bottom: 1rem;
	}
	p {
		margin-bottom: 1rem;
	}
	.btn {
		@include media-breakpoint-down(sm) { padding: 1rem 2rem; display: block; }
	}

	&.bg-dark {
		h3 {
			color: $white;
		}
		p {
			color: $white;
		}
	}
	&.bg-primary {
		color: $white;
		background-image: url('../img/svg/shape_pattern_op8.svg'); 
		background-position: top 300px;
		background-size: 300%;
		.btn {
			@extend .btn-secondary;
		}
	}
}

.sidebar {
	.cta-block {
		margin-top: 1.5rem;
		padding: 5rem 1.5rem;
		background-size: 300%;
		h3.widget-title {
			color: $white;
			font-weight: 700;
			margin-bottom: 1rem;
			@include fontSize(24px);
		}
		p {
			margin-bottom: 1.5rem;
			line-height: 1.6;
		}
	}
}

.wrapper-horizontal-ctas {
	padding: 0 0 8rem;
	hr {
		margin-bottom: 3.5rem;
	}
	.cta-block {
		@include media-breakpoint-down(sm) { margin-bottom: 1rem; }
	}
}

.cta__full {
	position: relative;
	overflow: hidden;
	margin-top: 6rem;
	margin-bottom: 4rem;
	@include media-breakpoint-down(sm) {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	.btn-primary {
		position: relative;
		z-index: 99;
		min-width: 304px;
	}

	.page-template-careers & {
		margin-top: 0;
		margin-bottom: 0;
	}
}
.cta__full-content {
	max-width: 712px;
	@include media-breakpoint-only(md) {
		margin-left: 5rem;
	}
	@include media-breakpoint-up(lg) {
		margin-left: 11.375rem;
	}
}
.cta__full-footer {
	max-width: 780px;
	@include media-breakpoint-only(md) {
		margin-left: 5rem;
	}
	@include media-breakpoint-up(lg) {
		margin-left: 11.375rem;
	}
}

.cta__full-footer {
	display: flex;
	gap: 2rem;
	margin-bottom: 2rem;

	@include media-breakpoint-down(md) {
		flex-direction: column;
	}

	.fab {
		margin-left: 1.5rem;

		@include media-breakpoint-down(md) {
			margin-left: auto;
		}
	}
}

.cta--latest-news {
	.btn {
		min-width: inherit;
		display: flex;
		align-items: center;

		&:hover {
			background-color: #cd7b00;
		}

		&.arrow {
			&:after {
				display: inline-block;
				pointer-events: none;
				content: "";
				margin-left: .5rem;
				transition: all .2s ease-in-out;
				width: 16px;
				height: 28px;
				background-repeat: no-repeat;
				background-image: url("data:image/svg+xml,%3Csvg width='21' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.59 1.164l-.375.328a.635.635 0 000 .797l3.937 3.89H.574a.57.57 0 00-.562.563v.469c0 .328.234.562.562.562h17.578l-3.937 3.938a.635.635 0 000 .797l.375.328c.187.234.562.234.797 0l5.437-5.438a.54.54 0 000-.796l-5.437-5.438c-.235-.234-.61-.234-.797 0z' fill='%23fff'/%3E%3C/svg%3E");
				background-size: contain;
				background-position: center;

				@include media-breakpoint-down(md) {
					margin-left: auto;
				}
			}
		}
	}

	.cta__full-image {
		width: 70%;
	}
}
.services-group ~ .cta__full {
	margin-top: 0;
}
.cta--dark {
	background-color: $black;
	
	.h1, p {
		color: $white;
		position: relative;
		z-index: 10;
	}
	.h1 {
		display: inline-block;
		margin-top: 1rem;
		position: relative;
		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
		}
		@include media-breakpoint-up(md) {
			padding-bottom: 1.5rem;
			padding-top: 1.5rem;
			&:before {
				content: "";
				width: 200px;
				height: 100%;
				position: absolute;
				background-color: $black;
				z-index: -1;
				top: 0;
				left: 0;
			}
		}
	}
	p {
		@include media-breakpoint-down(sm) {
			@include fontSize(16px);
		}
		@include fontSize(20px);
	}
	.container {
		@include media-breakpoint-down(sm) {
			padding-top: 2rem;
			padding-bottom: 3rem;
		}
		@include media-breakpoint-up(md) {
			padding-top: 5.25rem;
			padding-bottom: 5.25rem;
		}
	}
}

.cta__full-image {
	position: absolute;
	right: 0;
	top: 0;
	width: 65%;
	height: 100%;
	@include media-breakpoint-down(md) {
		width: 75%;
	}
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 0 !important;
	}

	&:after {
		content: "";
		left: 0;
		top: 0;
		height: 100%;
		width: 500px;
		position: absolute;
		background: linear-gradient(270deg, rgba(21, 21, 21, 0) 0%, $black 100%);

		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
}

.cta--more-info__heading {
	color: $primary;
}
.cta--more-info {
	.btn {
		min-width: 304px;
		@include buttonIconContact;
	}
}