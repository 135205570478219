.site-header {
	background-color: transparent;
  position: absolute;
	width: 100%;
  z-index: 10;

  @include media-breakpoint-down(md) {
    background-color: $black;
    position: relative;
    .navbar {
      padding: 1rem 0;
    }

  }

	a, a:visited {
		color: $white;
    border: 0 none;
	}
	color: $white;

	&__cta--phone {
		color: white;
    font-family: $font-regular;
    margin-left: 1.125rem;
    margin-top: 3px;

    a:hover {
      color: $primary;
    }
    
    @include media-breakpoint-down(md) {
      display: none;
    }

		.fa-mobile {
      font-size: 20px;
      position: relative;
      top: -2px;
			@include rotate(-30);
		}

    p {
      @include fontSize(10px);
      text-transform: uppercase;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.33em;
      padding: 0;
      margin: 0;
      margin-left: 0.5rem;
      display: inline-block;
      line-height: 1.2;

      span {
        @include fontSize(14px);
        display: block;
        letter-spacing: normal;
      }
    }
	}
	.navbar-brand {
		img {
			max-width: 128px;
      @include media-breakpoint-down(sm) {
        max-width: 100px;
      
      }
      border: 0 none;
		}
	}
}

.header__footer {
	background-color: $grey-light;
	padding: 15px;
	text-align: left;
}

// Header notification bar

.site-notification-bar {
  background-color: $black;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .container {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      flex-flow: column wrap;
      justify-content: center;
    }
  }
  p {
    margin-bottom: 0;
    color: $white;
    @include media-breakpoint-down(sm) {
      @include fontSize(14px);
      text-align: center;
    }
    span {
      text-transform: uppercase;
      font-weight: $fw-semi;
    }
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: $white;
    }
  }
}

.site-notification-bar__right {
  display: flex;
  align-items: center;
  @include media-breakpoint-up(md) {
    margin-left: auto;
  }
}

.site-notification-bar__socials {
  margin-bottom: 0;
  display: flex;
  li {
    padding-left: 1rem;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      width: 1.2rem;
      height: 1rem;
      svg {
        path {
          fill: $white;
        }
      }
    }
    a:hover {
      svg path {
        fill: $orange;
      }
    }
  }
  li:before {
    display: none;
  }
}