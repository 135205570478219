
.vp-portfolio__item-meta-categories {
    flex-flow: column;
    order: 2;
    margin-top: 1rem;
    margin-bottom: 1rem !important;
  }
  
  .vp-portfolio__item-meta-category {
    margin-bottom: .5rem;
    font-size: 12px;
    font-weight: bold !important;
    letter-spacing: 1px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .vp-portfolio__items-style-fade .vp-portfolio__item-meta {
    justify-content: flex-end;
  }
  .vp-portfolio__item-meta {
    @media screen and (min-width: 1024px) {
      padding-left: 2.5rem !important;
    }
  }
  .vp-portfolio__item-meta-wrap {
    position: relative;
    @media screen and (min-width: 1024px) {
      overflow: hidden !important;
      &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        left: 1.5rem;
        bottom: 0;
        background-color: $primary;
        transform: translateY(100%);
        transition: all .3s ease-in-out;
      }
    }
  }
  
  .vp-portfolio__item {
    &:hover {
      .vp-portfolio__item-meta-wrap {
        &:after {
          transform: translateY(0);
        }
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      height: 24px;
      width: 24px;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.892 10.093a.57.57 0 00-.562-.562h-3.938V5.593a.57.57 0 00-.562-.562h-.375c-.282 0-.563.28-.563.562v3.938H5.955c-.282 0-.563.28-.563.562v.375a.57.57 0 00.563.563h3.937v3.937a.57.57 0 00.563.563h.375a.542.542 0 00.562-.563v-3.937h3.938a.542.542 0 00.562-.563v-.375zm8.86 13.735c.234-.188.234-.563 0-.797l-6.047-6.047c-.094-.094-.235-.14-.422-.14h-.375c1.547-1.735 2.531-4.032 2.531-6.563 0-5.344-4.313-9.657-9.656-9.75C5.298.484.845 4.937.939 10.42c.047 5.344 4.36 9.61 9.75 9.61a9.784 9.784 0 006.516-2.485v.375c0 .14.047.282.187.422l6.047 6.047c.188.188.563.188.797 0l.515-.563zM18.939 10.28a8.232 8.232 0 01-8.25 8.25 8.203 8.203 0 01-8.25-8.25 8.232 8.232 0 018.25-8.25c4.547 0 8.25 3.703 8.25 8.25z' fill='%23fff'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 9;
      pointer-events: none;
    }
  }

  // Hover 
  .vp-portfolio__item-meta-title {
    @include media-breakpoint-down(md) {
      font-size: 1rem !important;
      line-height: 1.25rem !important;
    }
    @include media-breakpoint-only(lg) {
      font-size: 1.25rem !important;
      line-height: 1.4rem !important;
    }
  }
  
  // Filters
  
  .vp-portfolio__filter-wrap {
    border-radius: .5rem;
    margin-top: 0;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 1rem;
    }
    @include media-breakpoint-down(md) {
      padding: 2rem;
      background: $grey-light;
    }
  
    @include media-breakpoint-up(md) {
      padding: 1.75rem 1.75rem 1.5rem 1.75rem;
      background: $grey-light;
    }
  }
  
  .vp-filter__style-dropdown {
    --vp-filter-minimal--items__padding-h: 0.7em;
    --vp-filter-dropdown--items__arrow-size: 1em;

    select {
      margin: 0 0 0 1rem;
      background-color: white !important;
    }

    &:before {
      content: "Filter gallery by:";
      display: flex;
      align-items: center;
      padding-right: .5rem;
      font-size: 1rem;
  
      @include media-breakpoint-down(md) {
        content: "Filter:";
      }
    }
  }
  
  .vp-filter__style-minimal .vp-filter__item>* {
    font-size: 1.25rem;
    text-decoration: underline;
    text-transform: none;
    font-weight: 400;
  
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  
    &:hover {
      text-decoration: none;
    }
  }
  
  // Turn off commas on tags
  
  .vp-portfolio__items-style-fade .vp-portfolio__item-meta-categories .vp-portfolio__item-meta-category::after {
    display: none;
  }
  
  // Override items gap on smaller screens
  .vp-portfolio__items {
    @media screen and (max-width: 1365px) {
      --vp-items__gap: 24px !important;
    }
  }
  
  .vp-portfolio__item .vp-portfolio__item-img a {
    position: unset !important;
}


  