.content-container {
    h1, h2, h3, h4, h5, h6, p, ol, ul {
        max-width: 804px;
    }
    p+h1, p+h2, p+h3, p+h4, p+h5, p+h6, p+ul, p+ol {
        margin-top: 3rem;
    }
    p+figure, p+img {
        margin-top: 4rem;
    }
    figure {
        img {
            border-radius: 1rem;
        }
    }
}

.about-us {
    position: relative;
    @include media-breakpoint-up(lg) {
        &:before {
            content: "";
            z-index: -10;
            position: absolute;
            right: -24px;
            top: -24px;
            width: 762px;
            height: 688px;
            background-size: auto;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 762 688'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M431.464.008c-53.363-.692-97.2 42.19-97.891 95.74v94.146c-.345 58.746-48.116 105.993-106.657 105.646H97.891C44.527 294.848.69 337.73 0 391.28v200.139c.207 53.55 43.699 96.779 97.062 96.571h233.405c53.363.762 97.2-42.12 97.96-95.67v-94.216c.345-58.746 48.116-105.993 106.657-105.646h129.025c53.364.693 97.201-42.189 97.891-95.74V96.302c-.414-53.55-43.975-96.71-97.338-96.294H431.464z' fill='%23F7F8FC'/%3E%3C/svg%3E");
        }
    }
}

.hero-related {
    margin-top: 3rem;
    @include media-breakpoint-down(md) {
        background: $grey-lighter;
        padding: 2rem;
        margin-bottom: 3rem;
        margin-top: 1rem;
        border-radius: 1rem;
    }
}

.hero-related__list {
    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }
}

.hero-related__item {
    padding-left: 0;
    margin-bottom: 1rem;
    &:before {
        display: none;
    }
    &:last-of-type {
        margin-bottom: 0;
    }

    a {
        .link {
            text-decoration: underline;
        }
        span:not(.link) {
            display: none;
        }
    }

    &.active {
        a {
            display: flex;
            gap: .5rem;
            color: $black;

            span:not(.link) {
                display: inline-block;
            }
        }
    }
}

.hero-related__item--contact {
    span {
        display: inline-block;
        margin-right: .5rem;
    }
}

.hero-related__item__icon {
    width: 1.5rem;
    display: inline-block;
    color: $black;
}

.hero-related__item__text {
    display: inline-block;
    margin-left: .5rem;
    text-decoration: underline;
}


.seo-content {
    .section__heading {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    ul {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 0;

        @include media-breakpoint-down(xs) {
            flex-flow: column;
        }

        a {
            color: $black;
            text-decoration: underline;

            &:hover {
                color: $primary;
                text-decoration: none;
            }
        }

        li {
            font-size: 1rem;
            padding-left: 0;
            margin-bottom: 1.5rem;
            font-weight: 600;
            &:last-of-type {
                margin-bottom: 0;
            }
            &:before {
                display: none;
            }
            @include media-breakpoint-between(sm, md) {
                width: 50%
            }
            @include media-breakpoint-up(lg) {
                width: 33%
            }
        }
    }
}

.seo-content__cta {
    background: $white;
    border-radius: 1rem;
    overflow: hidden;
    img {
        width: 100%;
        height: auto;
        display: block;
    }
}
.seo-content__cta__body {
    padding: 2rem;
    p, h3 {
        color: $black;
    }
    .btn {
        width: 100%;
    }
}