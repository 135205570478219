.section__heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    .careers & {
        margin-bottom: 2rem;
    }

    @include media-breakpoint-down(md) {
        flex-flow: column wrap;
        align-items: flex-start;
    }

    p {
        margin-bottom: 0;
    }

    .highlight-line & {
        margin-bottom: 1rem;

        h1, h2 {
            position: relative;
            z-index: 10;
            line-height: 6.5rem;
            @include media-breakpoint-down(sm) {
                line-height: 3rem;
            }
        }

        .bg-dark & {
            h1, h2 {
                background: $black;
            }
        }
        .bg-light & {
            h1, h2 {
                background: $grey-lightest;
            }
        }
    }
}

.section__heading__intro {
    max-width: 920px;
    p {
        font-size: 1.25rem;
        line-height: 2rem;
        @include media-breakpoint-down(sm) {
            font-size: 1.0625rem;
            line-height: 1.6rem;
        }
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
    }
    @include media-breakpoint-up(md) {
        padding-right: 2rem;
    }
}
.section__heading__button {
    min-width: 304px;
    .btn {
        width: 100%;
    }
}

.highlight-line {
    position: relative;
    padding-top: 5rem;
	padding-bottom: 4.5rem;
    z-index: 2;
    &:before {
        content: "";
        height: 100%;
        width: 1px;
        position: absolute;
        left: 5%;
        top: 0;
        background: $primary-light;
        box-sizing: border-box;
        animation: line ease-in-out 1.5s forwards;
        
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

@keyframes line {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
