// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder. 
// Just copy a variable from /sass/bootstrap/_variables.scss, paste it here and edit the value.
// As example you find one of the most important Bootstrap variable below: $brand-primary.
// Thats the ugly Bootstrap blue used for all the links, primary buttons etc.
// Just add your color code here, compile the theme.scss file to change this color everywhere


//
// Color system
$primary: #f39200; 

.text-primary {
	color: $primary !important;
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px
);

