.form-container {
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  background: #ffffff;
  form {
    padding: 4rem 10%;
    @include media-breakpoint-down(sm) {
      padding: 3rem 1.5rem;
    }
  }
  @include media-breakpoint-only(lg) {
    margin-right: 2.5rem;
  }
}

.form-control {
  height: 50px;
  border: 1px solid #E1E2E4;
  background: #F7F8FC;
  border-radius: $border-radius-sm;
  font-family: $font-regular;
  @include fontSize(12px);
}

label {
  font-weight: 600;
  @include fontSize(16px);
  margin-left: 0.25rem;
  width: 100%;
  span {
    text-align:right;
    float: right;
    @include fontSize(12px);
    text-transform: none;
    font-family: $font-regular;
    padding-right: 0.5rem;
  }
}

.form--newsletter {
  label {
    display: block;
    width: 100%;
    color: $white;
  }
  p {
    padding: 0;
    margin:0;
  }
  p.label {
    @include fontSize(18px);
    margin-bottom: 0.5rem;
  }
  
  span.your-email {
    @extend .form-control;
    padding: 0 0 0 16px;
    border: 0 none;
    box-shadow: none;
    margin-left: -8px;
    &:before {
      content: "\f0e0";
      font-family: 'Font Awesome 5 Pro';
      @extend .fal;
      color: $black;
      font-size: 16px;
      position: relative;
      top: 3px;
    }
    .form-control {
      box-shadow: none;
      border: 0 none;
      text-transform: uppercase;
    }
  }

  input[type=submit] {
    @extend .btn-tertiary;
    @include media-breakpoint-down(sm) { margin-right:0; padding: 1rem 2rem; }
    @include media-breakpoint-down(xs) { margin-right:0; padding: 1rem; display: block; width: 100%; }
  }

}


textarea:focus,
input[type="text"].form-control:focus,
input[type="password"].form-control:focus,
input[type="datetime"].form-control:focus,
input[type="datetime-local"].form-control:focus,
input[type="date"].form-control:focus,
input[type="month"].form-control:focus,
input[type="time"].form-control:focus,
input[type="week"].form-control:focus,
input[type="number"].form-control:focus,
input[type="email"].form-control:focus,
input[type="url"].form-control:focus,
input[type="search"].form-control:focus,
input[type="tel"].form-control:focus,
input[type="color"].form-control:focus,
textarea.form-control:focus,
.uneditable-input:focus {   
  border: 1px solid #ccc;
  background-color: #f4f2fa;
  -webkit-box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.1);
   box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.1);
   -webkit-box-shadow: none;
  -moz-box-shadow: none;
   box-shadow: none;
  outline: 0 none;
}

.form--newsletter {
  input[type="email"].form-control:focus {
    box-shadow: none;
    border: 0 none;
    background-color: #ffffff;
    width: 100%;
  }
}




.gfield_label {
  .gfield_required {
    display: none;
  }
  &::after {
    content:"Optional";
    text-transform: none;
    font-family: $font-regular;
    @include fontSize(12px);
    margin-right: 0.75rem;
    float: right;
  }
}

.gfield_contains_required {
  .gfield_label::after {
    display: none;
  }
}

.gform_body {
    display: block;
    flex-wrap: wrap;
}

.gform_fields {
  @extend .list-unstyled;
  @extend .row;
  margin-bottom: 0;
  li {
    &:before {
      display: none;
    }
  }
  .gfield{
    @extend .col-sm-12;
    margin-bottom: 0.5rem;
    &:before {
      display: none;
    }
  }
  .gfield.col-sm-6{
    @extend .col-sm-6;
  }
  .gfield.col-sm-5{
    @extend .col-sm-5;
  }
  .gfield.col-sm-4{
    @extend .col-sm-4;
  }
  .gfield.col-sm-3{
    @extend .col-sm-3;
  }
  .gfield.col-sm-2{
    @extend .col-sm-2;
  }
  .gfield.col-md-6{
    @extend .col-md-6;
  }
  .gfield.col-md-5{
    @extend .col-md-5;
  }
  .gfield.col-md-4{
    @extend .col-md-4;
  }
  .gfield.col-md-3{
    @extend .col-md-3;
  }
  .gfield.col-md-2{
    @extend .col-md-2;
  }

  .captcha-field {
    margin-left: 0.75rem;
    margin-bottom: 1.5rem;
    .gfield_label {
      display: none;
    }
  }


  input[type=text],
  input[type=search],
  input[type=url],
  input[type=tel],
  input[type=number],
  input[type=range],
  input[type=date],
  input[type=month],
  input[type=week],
  input[type=time],
  input[type=datetime],
  input[type=datetime-local],
  input[type=color],
  input[type=email],
  input[type=file],
  select,
  textarea {
    @extend .form-control;
    &.error {
      border: 1px solid $red;
    }
  } 

  

  
  label.error{
    color: $red;
    @include fontSize(17px);
    margin-left: 0.5rem;
  }

  .gfield_checkbox {
    padding-left: 0;
  }
  // Custom checkbox styles
  /* Base for label styling */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 3.4em;
    padding-top: 0.25rem; 
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0; top: 2px;
    width: 2.5rem; height: 2.5rem;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
  }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      content: '';
      position: absolute;
      top: .4rem; left: .75rem;
      height: 26px;
      width: 20px;
      background-image: url('../img/checkboxtick.png');
      background-repeat: no-repeat;
      background-position: center;
      transition: all .2s;
    }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }
  [type="checkbox"]:disabled:checked + label:after {
    color: #999;
  }
  [type="checkbox"]:disabled + label {
    color: #aaa;
  }

  .ginput_container_consent {
    margin-top: 0.5rem;
  }
  .ginput_container_consent + .gfield_description {
    margin-top: -.55rem;
    @include fontSize(10px);
    padding: 0;
    margin-left: 58px;
  }

  .ginput_container_fileupload {
    @extend .custom-file;
    width: auto;
    &:before {
      @extend .btn;
      @extend .btn-secondary;
      height: 50px;
      width: 195px;
      content: "Choose File";
      background-color: $black;
      color: $white;
      position: absolute;
      z-index: 1;
    }


    input {
      @extend .custom-file-input;
      width: 195px;
      border: 0 none;
      background: transparent;
      box-shadow: none;
      z-index: 10;
      &:focus {
        box-shadow: none;
        background: transparent;
      }
    }
    .validation_message {
      content: "No file chosen";
      padding: 0.5rem 0;
      @include fontSize(11px);
      border: 0 none;
      background: transparent;
    }
  }

  span.file-details {
    @include fontSize(14px);
    margin-left: 0.5rem;
    display: inline-block;
    line-height: 40px;
    top: 8px;
    position: relative;
  }

  /* accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    border: 1px dotted #999;
  }

  /* hover style just for information */
  label:hover:before {
    border: 1px solid #bbb !important;
  }

  .hidden-label label.gfield_label {
    display: none;
  }

}

.col-md-6 {
  textarea {
    height: 340px;
  }
}


.gfield_description {
  @extend .alert;
}
.gform_footer {
  position: relative;
  @include media-breakpoint-up(md) {
    max-width: 304px;
  }
  &:after {
    pointer-events: none;
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3E%3Cpath d='m22.75.234-21 12.094c-1.078.61-.984 2.203.14 2.672l6.61 2.719v4.78c0 1.454 1.734 2.063 2.625.985l2.86-3.468 5.905 2.437a1.5 1.5 0 0 0 2.063-1.172l3-19.547c.188-1.218-1.125-2.156-2.203-1.5ZM10 22.5v-4.125l2.531 1.031L10 22.5Zm10.5-1.406-9.703-4.032 9.375-11.015c.234-.281-.14-.656-.422-.422L7.797 15.844 2.5 13.64 23.5 1.5l-3 19.594Z' fill='%23fff'/%3E%3C/svg%3E");
  }
}
.gform_button {
  margin-left: unset !important;
  @extend .btn;
  @extend .btn-primary;
  @include media-breakpoint-down(xs) {
    margin-right:0; padding: 1rem; display: block; width: 100%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: unset !important;
  }
  min-width: 304px;
}
.validation_error {
  @extend .alert;
  @extend .alert-danger;
}
.validation_message {
  @extend .alert;
  @extend .alert-success;
}
.gfield_required {
  color: $red;
}
.gform_wrapper ul.gfield_radio li, .gform_wrapper ul.gfield_checkbox li {
  @extend .form-check;
}
.gform_validation_container {
  display: none;
}
.gfield_error .ginput_container {
  margin-bottom: 10px;
}

.gform_footer{
  display: flex;
  input {
    margin-left: auto;
    max-width: 304px;
  }
}

#gform_4 {
  .gform_footer{
    margin: 0;
    margin-bottom: 2rem;
  }
  .ginput_container_consent {
    padding:0;
  }
  .gfield_consent_label {
    padding-left: 4em;
    margin-left: 0;
  }
  .ginput_container_consent + .gfield_description {
    margin-left: 48px;
  }
}

#gform_fields_1 {
  height: 0;
}

.form--newsletter{
  .form-inline {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .gform_body {
    flex-flow: row;
    width: 69%;
  }
  .gform_fields {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    .gfield {
      padding: 0; margin-bottom: 0;
    }
    label {
      display: block;
      width: 100%;
      color: $white;
      text-transform: none;
      text-align: left;
      @include fontSize(18px);
      margin-bottom: 0.5rem;
      font-family: $font-regular;
      margin-left: 0;
    }
    .ginput_container_email {
      @extend .form-control;
      @extend .d-flex;
      padding: 0 0 0 16px;
      border: 0 none;
      box-shadow: none;

      &:before {
        content: "\f0e0";
        font-family: 'Font Awesome 5 Pro';
        @extend .fal;
        color: $black;
        font-size: 16px;
        position: relative;
        top: 17px;
      }
      input[type=email] {
        box-shadow: none;
        border: 0 none;
        text-transform: uppercase;
      }
    }
  }
  .gform_footer {
    border: 0 none;
    margin: 0;
    position: relative;
    width: 30%;
    .btn {
      @include fontSize(12px);
    }
    &:after {
      pointer-events: none;
      content: "";
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 21px;
      height: 13px;
      background-repeat: no-repeat;      
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.578.188l-.375.328a.635.635 0 000 .796l3.938 3.891H.563A.57.57 0 000 5.766v.468c0 .329.234.563.563.563H18.14l-3.938 3.937a.635.635 0 000 .797l.375.328c.188.235.563.235.797 0l5.438-5.437a.54.54 0 000-.797L15.375.187c-.234-.234-.61-.234-.797 0z' fill='%232B2B2B'/%3E%3C/svg%3E");
    }
  }
  small {
    a:hover {
      color: $primary;
    }
  }

}


.aside-form-container {
  padding: 2rem 1rem;
  @include media-breakpoint-down(sm) { padding: 1rem 0; }
  .gform_heading {
    padding: 0 5px;
    .gform_description {
      strong {
        line-height: 2;
      }
    }
  }

  .gform_fields .gfield {
      margin-bottom: 1.625rem;
  }
  .gform_body {
    padding: 0.5rem;
  }
  textarea {
    height: 226px;
  }

  .captcha-field {
    margin-left: 0;
    margin-bottom: 1rem;
    .gfield_label {
      display: none;
    }
  }
}

h3.gform_title {
  @include fontSize(34px);
  @include media-breakpoint-down(sm) { @include fontSize(24px); }
  margin-bottom: 1.25rem;
}

.captcha-field {
  @include media-breakpoint-down(sm) { 
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.contact-wrapper {
  .rounded-box.bg-grey {
    @include media-breakpoint-down(xs) { display: block; padding-bottom: 0.25rem; margin-bottom: 2rem; }
  }
}
