.single-post__image {
    position: relative;
    @include media-breakpoint-up(md) {
        transform: translateX(182px);
        &:before {
            content: "";
            position: absolute;
            width: 165px;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(90deg, #F7F8FC 12.72%, rgba(247, 248, 252, 0.6) 62.72%, rgba(247, 248, 252, 0) 100%);
        }
    }
}
.single-post__intro {
    @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
    }
    @include media-breakpoint-up(md) {
        align-items: center;
        margin-bottom: 2rem;
    }
}
.single-post__share {
    display: flex;
    max-width: 894px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.single-post__title {
    z-index: 9;
    @include media-breakpoint-up(md) {
        position: absolute;
    }
}

.related-service {
    position: relative;
    max-width: 621px;
    margin-top: 3rem;
    padding-top: 3rem;
    &:before {
        content: "";
        left: 0;
        top: 0;
        position: absolute;
        height: 2px;
        width: 100%;
        background: linear-gradient(90deg, #F39200 60.49%, #F0F2F7 100%);
        transition: .3s ease-in-out;
    }
}

.related-service__list {
    @include media-breakpoint-up(md) {
        max-width: 304px !important;
    }
    .btn {
        width: 100%;
    }
    li {
        padding-left: 0;
        &::before {
            display: none;
        }
    }
}