.navbar-toggler {
  border: none !important;
  padding: 0;
}
.navbar-toggler-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.navbar {
  padding: 3rem 0rem;

  @include media-breakpoint-down(md) {
    padding: 1.5rem 0rem;
  }

  .navbar-nav {
     @include media-breakpoint-down(md) {
      text-align:center;
      margin: 2rem auto;
    }
    .menu-item {
      padding: 0;
      &:before {
        display: none;
      }
    }

    .nav-link {
      color: $white;
      @include fontSize(14px);
      padding: 1rem;
      white-space: nowrap;
      line-height: 1;
      font-weight: 400;
      font-family: $font-regular;
      border: 0 none;
      &:hover {
        color: $primary;
        transition: color 0.5s;
      }
      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .text-uppercase {
      text-transform: uppercase;

      .nav-link {
        font-weight: 800;
      }
    }
    .nav-item:last-child {
      padding-right: 0;
    }

    .menu-item-29 { // contact link
      @include media-breakpoint-up(lg) {
        padding-left: 1rem;
        margin-left: 1rem;
        border-left: 1px solid  rgba(255, 255, 255, 0.5);
      }
    }

    .dropdown.show {
      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    .dropdown-menu {
      margin: 0;
      border: 0 none;
      background: none;
      text-transform: none;
      transition: all 0.5s;
      // width: 100%;

      @include media-breakpoint-up(lg) {
        background-color: rgba(0,0,0,0.925);
      }

      .dropdown-item {
        color: $white;
        font-weight: $fw-semi;
        white-space: unset;

        @include fontSize(14px);
        padding: 0.5rem 1rem;

        @include media-breakpoint-down(sm) {
          @include fontSize(14px);
          padding: 0.75rem 1rem;
        }

        &:hover {
          background: transparent;
          color: $primary;
        }
        &:focus {
          background: transparent;
          color: $primary;
        }
        &.active,
        &:active {
          color: $primary;
          background: transparent;
        }
      }
    }
  }
}

// Services menu

.dropdown-menu.show {
  .dropdown-menu {
    display: flex;
    flex-flow: row wrap;
    padding-top: 0;
    width: 100%;

    .menu-item {
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
  .menu-item {
    @include media-breakpoint-down(md) {
      background: #2A2A2A;
      margin-bottom: 1rem;
      border-radius: $border-radius;
    }
  }
}

.menu-item-has-children {
  position: relative;

  > .dropdown-item {
    display: none;
  }

  .dropdown-menu .show & {
    display: flex;
  }
}

.dropdown-toggle {
  &:after {
    content: "";
    border: none;
    margin-left: .5rem;
    vertical-align: 0.05rem;
    display: inline-block;
    background-repeat: no-repeat;
    width: 13px;
    height: 8px;
    transition: all .3s ease-in-out;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.042 7.432a.644.644 0 00.903 0l5.332-5.305c.246-.273.246-.684 0-.93l-.63-.629c-.245-.246-.655-.246-.929 0L6.508 4.78 2.268.57c-.273-.247-.684-.247-.93 0l-.629.628c-.246.246-.246.657 0 .93l5.332 5.305z' fill='%23fff'/%3E%3C/svg%3E");
  }
  &:hover {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.042 7.432a.644.644 0 00.903 0l5.332-5.305c.246-.273.246-.684 0-.93l-.63-.629c-.245-.246-.655-.246-.929 0L6.508 4.78 2.268.57c-.273-.247-.684-.247-.93 0l-.629.628c-.246.246-.246.657 0 .93l5.332 5.305z' fill='%23dc7200'/%3E%3C/svg%3E");
    }
  }
}

.menu-services-trigger {
  .nav-link {
    position: relative;
    display: flex;
    align-items: center;
    &:after {
      content: "";
      border: none;
      margin-left: .5rem;
      vertical-align: 0.05rem;
      display: inline-block;
      background-repeat: no-repeat;
      width: 13px;
      height: 8px;
      transition: all .3s ease-in-out;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.042 7.432a.644.644 0 00.903 0l5.332-5.305c.246-.273.246-.684 0-.93l-.63-.629c-.245-.246-.655-.246-.929 0L6.508 4.78 2.268.57c-.273-.247-.684-.247-.93 0l-.629.628c-.246.246-.246.657 0 .93l5.332 5.305z' fill='%23fff'/%3E%3C/svg%3E");
    }
    &:hover {
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.042 7.432a.644.644 0 00.903 0l5.332-5.305c.246-.273.246-.684 0-.93l-.63-.629c-.245-.246-.655-.246-.929 0L6.508 4.78 2.268.57c-.273-.247-.684-.247-.93 0l-.629.628c-.246.246-.246.657 0 .93l5.332 5.305z' fill='%23dc7200'/%3E%3C/svg%3E");
      }
    }
  }
}

.menu-services-trigger--open {
  .nav-link {
    &:after {
      transform: rotate(180deg);
    }
  }
}


.nav-item.show {
  .dropdown-toggle {
    color: $primary !important;
  }
  .dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.042 7.432a.644.644 0 00.903 0l5.332-5.305c.246-.273.246-.684 0-.93l-.63-.629c-.245-.246-.655-.246-.929 0L6.508 4.78 2.268.57c-.273-.247-.684-.247-.93 0l-.629.628c-.246.246-.246.657 0 .93l5.332 5.305z' fill='%23dc7200'/%3E%3C/svg%3E");
    transform: rotate(180deg);
  }
}

.navbar-expand-lg > .container {
    @include media-breakpoint-down(sm) { padding-right: 15px; padding-left: 15px; }
}

.social-menu-container {
  text-align: right;
  @include media-breakpoint-down(sm) { text-align: left; margin: 2rem 0 1rem; }
}
.social-menu {
  list-style-type: none;
  margin: 0;
  padding:0;
  margin-left: 1.5rem;
  color: $white;
  @include media-breakpoint-down(sm) { margin-left:0; }
  li {
    display: inline;
    padding-left: 0;
    &:first-child {
      @include fontSize(12px);
      padding-right: 0.25rem;
    }
    a {
      color: $white;
      padding: 0 0.875rem;
      border: 0 none;
      &:hover {
        color: $primary;
      }
      
    }
    &:last-child {
      a {
        padding-right: 0;
      }
    }
    &:before {
      display: none;
    }
  }
}

.social-menu-modal {
  list-style-type: none;
  margin: 0;
  padding:0;
  text-align: center;
  color: $primary;
  li {
    display: inline;
    padding:0;
    &:before {
      display: none;
    }
    a {
      color: $primary;
      padding: 1.5rem;
      border: 0 none;
      i{
        font-size: 24px;
      }
      &:hover {
        color: $black;
      }
    }
  }
}


/// Breadcrumbs
.wrapper-breadcrumbs {
  padding: 4rem 0;
  @include fontSize(12px);
  text-transform: uppercase;
  font-family: $font-regular;
  color: $black;
  @include media-breakpoint-down(sm) { padding: 1.5rem 0; }
  a, a:visited {
    border: 0 none;
    color: $black;
  }
  a:hover {
    color: $primary;
  }
  p {
    margin-bottom: 0;
    @include fontSize(12px);
  }
}



.heading-share, .social-links {
  margin-bottom: 2.5rem;
  p {
    margin-bottom: 0;
    margin-right: 1rem;
  }
  .share-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 0.75rem;
    display: flex;

    li {
      display: flex;
      align-items: center;
      padding-left: 0;
      margin-right: .5rem;

      &:before {
        display: none;
      }

      a {
        color: $black;
        width: 48px;
        height: 48px;
        border: 1px solid #dadada;
        background: $white;
        border-radius: $border-radius-lg;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s ease-in-out;

        @include media-breakpoint-down(sm) { font-size: 18px; }

        &:hover {
          background: $primary;
          border-color: $primary-dark;
          color: $white;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

.social-links {
  .share-items {
    margin-top: 0;
  }
}




/////// PAGINATION

ul.pagination {
  @extend .justify-content-center;
  text-align: center;
  margin-top: 2rem;
  li {
    &:before {
      display: none;
    }
  }

  .page-item {
    @include fontSize(18px);
    .page-link {
      border: 0 none;
      color: $black;
      &:hover {
        z-index: 2;
        color: $primary;
        background-color: transparent;
      }
    }
    &.active {
      font-weight: 600;
      .page-link {
        background: transparent;
        &.current {
          color: $primary;
        }
      }
    }
    .page-link:focus,.page-link:active {
       outline: none !important;
       box-shadow: none;
    }
  }
  .prev, .next {
    margin-top: 0.25rem;
    @include fontSize(12px);
    font-family: $font-regular;
    text-transform: uppercase;
  }
  .next {
    i {
      margin-left: 0.25rem;
    }
  }
  .prev {
    i {
      margin-right: 0.25rem;
    }
  }
}

.navbar-toggler-icon {
  display: none;
}

.navbar-toggler {
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.857143C0 0.392857 0.357143 0 0.857143 0H15.1429C15.6071 0 16 0.392857 16 0.857143C16 1.35714 15.6071 1.71429 15.1429 1.71429H0.857143C0.357143 1.71429 0 1.35714 0 0.857143ZM0 6.57143C0 6.10714 0.357143 5.71429 0.857143 5.71429H15.1429C15.6071 5.71429 16 6.10714 16 6.57143C16 7.07143 15.6071 7.42857 15.1429 7.42857H0.857143C0.357143 7.42857 0 7.07143 0 6.57143ZM15.1429 13.1429H0.857143C0.357143 13.1429 0 12.7857 0 12.2857C0 11.8214 0.357143 11.4286 0.857143 11.4286H15.1429C15.6071 11.4286 16 11.8214 16 12.2857C16 12.7857 15.6071 13.1429 15.1429 13.1429Z' fill='white'/%3E%3C/svg%3E%0A");
}