.why-choose {
    position: relative;
    h2 {
        position: relative;
        z-index: 10;
        @include media-breakpoint-up(lg) {
            font-size: 3.75rem;
            letter-spacing: -0.05em;
        }
    }
    &:before {
        z-index: 0;
        content: "";
        width: 868px;
        height: 778px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 868 778'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M491.518.01C430.737-.775 380.807 47.697 380.02 108.23V214.649c-.393 66.405-54.805 119.81-121.484 119.419h-146.96C50.796 333.284.865 381.678 0 442.21V668.831c.236 60.531 49.773 109.395 110.554 109.16h266.085c60.781.783 110.712-47.611 111.577-108.142V563.038c.471-66.405 54.805-119.81 121.483-119.419h146.725c60.781.783 110.711-47.611 111.576-108.142V108.856C867.607 48.325 817.913-.46 757.131.009H491.518z' fill='%23F2F3F6'/%3E%3C/svg%3E");
    }
}