.values {
    p {
        @include fontSize(14px);
        @include media-breakpoint-down(md) {
            @include fontSize(16px);
        }
    }
}

.values h3 {
    @include fontSize(24px);
}

.values__container {
    background: $grey-light;
    border-radius: 1rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
        margin-left: auto;
        max-width: 439px;
        margin-bottom: 4rem;
    }
}

.values__container__title {
    padding: 3rem 3rem 0 3rem;
    @include media-breakpoint-down(lg) {
        padding: 2rem 2rem 0 2rem;
    }
}
.values__container__text {
    padding: 0 3rem 0 3rem;
    @include media-breakpoint-down(lg) {
        padding: 0 2rem 0 2rem;
    }
}

.values__carousel {
    background: $black;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;
    overflow: hidden;

    &:before {
        content: "";
        background: $orange;
        height: 100%;
        width: 1px;
        position: absolute;
        left: 4rem;
        top: 0;
    }
    .slick-list {
        margin-bottom: 0;
        width: auto;
        display: block;
    }
    .slick-dots {
	    position: absolute;
	    bottom: 2rem;
	    display: block;
	    width: 100%;
	    padding: 0;
	    margin: 0;
        left: 3px;
	    list-style: none;
	    text-align: center;

	    li {
		    position: relative;
		    display: inline-block;
		    width: 20px;
		    padding: 20px;
		    margin: 0 5px;
		    padding: 0;
		    cursor: pointer;

        &:before {
          display: none;
        }
		    button {
			    font-size: 0;
			    line-height: 0;
			    display: block;
			    width: 20px;
			    height: 20px;
			    padding: 5px;
			    cursor: pointer;
			    color: transparent;
			    border: 0;
			    outline: none;
			    background: transparent;

			    &:before {
			    	font-family: 'slick';
			    	font-size: 36px;
			    	line-height: 20px;
			    	position: absolute;
			    	top: 0;
			    	left: 0;
			    	width: 20px;
			    	height: 20px;
			    	content: '•';
			    	text-align: center;
			    	opacity: .5;
			    	color: $grey-lighter;
			    	-webkit-font-smoothing: antialiased;
    				-moz-osx-font-smoothing: grayscale;
			    }
			}
		}

		li.slick-active button:before {
    		opacity: 1;
    		color: $primary;
		}
	}

    .slick-slide {
        margin: 0;
    }
}

.values__carousel-item {
    display: flex !important;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 10;
    @include media-breakpoint-up(lg) {
        max-width: 375px;
    }
    @include media-breakpoint-down(xl) {
        padding-right: 3%;
    }
}
.values__carousel-item__text {
    margin-left: .5rem;
    padding-right: .5rem;
    color: $white;
    @include fontSize(14px);
    @include media-breakpoint-down(md) {
        @include fontSize(16px);
    }
    @include media-breakpoint-up(lg) {
        max-width: 283px;
    }
}
.values__carousel-item__number {
    font-size: 5.5rem;
    font-weight: 600;
    color: $orange;
    display: block;
    background: $black;
    text-align: center;
    line-height: 6.5rem;
    flex-shrink: 0;
    width: 64px;
	text-align: center;
}