a {
  color: $primary;
  font-weight: 600;
  &:hover {
    color: $black;
  }
}

.btn-shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}


.btn {
  font-family: $font-regular;
  font-weight: 600;
  text-align: left;
  line-height: 1.5;
  border: 0 none;
  padding: 1.125rem 1.5rem;
  border-radius: $border-radius-sm;
  @include fontSize(16px);
}

.btn--max {
  width: 100%;
}

.btn-primary, a.btn {
  background-color: $primary;
  color: $white;
}
.btn-primary:hover, a.btn:hover {
  color: $white;
  background-color: #cd7b00;
  @extend .btn-shadow;
}

.btn-secondary {
  background-color: $black !important;
  color: $white;
}
.btn-secondary:hover {
    color: $white;
    background-color: $primary !important;
    @extend .btn-shadow;
}

.btn-tertiary {
  background: #E1E1E1 !important;
  color: #151515 !important;
  box-sizing: border-box;
  &:hover {
    color: $black !important;
    background-color: $white !important;
  }
}

.btn-outline {
  background: #2A2A2A !important;
  border: 1px solid #000000 !important;
  box-sizing: border-box;

  &:hover {
    background: $primary !important;
  }
}

.btn--flex {
  display: flex;
  justify-content: space-between;
}

// default BS4
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: $white;
    background-color: $primary-dark;
    border-color: $primary-dark;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}


// end

.btn-icon {
  @include buttonIcon;
}
.btn-icon--contact {
  @include buttonIconContact;
}
.btn-icon--down {
  @include buttonIconDown;
}
.btn-icon--orange {
  @include buttonIconOrange;
}

.btn-lg {
  @include fontSize(16px);
  border-radius: $border-radius-sm;
  padding: 1.125rem 1.5rem;
  @include media-breakpoint-down(md) { @include fontSize(16px); padding: 1rem 1.5rem; }
}

.btn-md {
  @include fontSize(15px);
  border-radius: $border-radius-sm;
  padding: 1.125rem 1.5rem;
  letter-spacing: 1px;
}


.btn-sm {
  @include fontSize(14px);
  border-radius: $border-radius-sm;
  padding: 0.75rem 1rem;
}


.btn-empty {
  border: 0 none;
  background-color: transparent !important;
  color: $white;
  box-shadow: none;
}
.btn-ghost, a.btn-ghost {
  border: 1px solid $white !important;
  background-color: transparent !important;
  color: $white;
  box-shadow: none;
  &:hover {
    color: $primary;
     border: 1px solid $primary;
  }
}
a.btn-ghost:hover  {
  color: $primary;
  border: 1px solid $primary;
}

.btn-white {
  background-color: $white !important;
  color: $black !important;
  border: 1px solid transparent !important;
  box-shadow: none;

  &:hover {
    border: 1px solid transparent !important;
    background: $black !important;
    color: $white !important;
  }
}

.share-link {
  @include fontSize(10px);
  font-family: $font-regular;
  text-transform: uppercase;
  border: 0 none;
  color: $black;
  cursor: pointer;
  &:hover {
    color: $primary;
  }
}
