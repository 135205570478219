.blockquote, blockquote {
  position: relative;
  font-family: $font-regular;
  @include media-breakpoint-down(sm) { margin: 3rem 1.5rem; }

  p, p:first-of-type{
    font-size: 48px;
    line-height: 1.2;
    font-weight: 400;
    position: relative;
    @include media-breakpoint-down(sm) { @include fontSize(24px); }
  }
  p:first-child:before {
    content: "";
    position: absolute;
    top: 0;
    left: -3rem;    
    font-weight: 600;
    height: 22px;
    width: 32px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 22'%3E%3Cpath d='M10.096 21.88H.304L9.52.088h6.24L10.096 21.88zm15.552 0h-9.792L25.072.088h6.24L25.648 21.88z' fill='%23DC7200'/%3E%3C/svg%3E");
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .blockquote-footer {
    position: relative;
    display: flex;
    align-items: center;
    &:before {
        content:"";
        display: inline-flex;
        background: $black;
        height: 1px;
        width: 2rem;
        margin-right: 1rem;
        @include media-breakpoint-up(lg) {
          width: 4rem;
        }
    }
    .name, .position, .cite {
        text-transform: uppercase;
        display: block;
        font-style: normal;
        font-size: 0.75rem;
        color: $black;
        font-weight: 600;
    }
    .name, .position {
        margin-bottom: .25rem;
    }
  }

  &.blockquote-sm {
    font-family: $font-regular;
    p{
      @include fontSize(18px);
      line-height: 1.5;
    }
    p:first-child:before {
      content: '\201C';
      @include fontSize(48px);
      position: absolute;
      top: 0;
      line-height: 1;
      left: -0.5em;    
      font-weight: 600;
    }
  }

}

.blockquote-footer__logo {
  max-width: 150px;
  margin-left: auto;
  @include media-breakpoint-down(md) {
    max-width: 120px;
    padding-left: .5rem;
  }
  @include media-breakpoint-up(lg) {
    max-width: 150px;
  }
}

.pullquote {
  padding: 0 1rem 0 1.5rem;
  margin: 3rem 0 3rem 2rem;
  border-left: 2px solid $primary;

  p {
    @include fontSize(26px);
    font-weight: bold;
  }
}

.aside {
  .blockquote {
    margin-left: 0;
    margin-top: 1rem;
    p {
      @include fontSize(16px);
      padding-left: 0;
      line-height: 1.425;
      font-family: $font-regular;
    }
  }
}
