.accordion {

  margin: 0 0.5rem;
  @include media-breakpoint-down(sm) {  margin: 0; }
  
  .card {
    background-color: transparent;
    border: 0 none;
    margin:0;
    border-bottom: 1px solid $black;
    padding: 1rem 0 1rem;
    &:last-child {
      border-bottom: 0 none;
    }
  }
  .card-header {
    background-color: transparent;
    padding: 1rem 0;
    border: 0 none;
    h5 {
      @include fontSize(24px);
      font-family: $font-bold;
      color: $black;
      padding: 0;
      cursor: pointer;
      @include media-breakpoint-down(sm) { @include fontSize(18px); }
    }
  }

  .card-body {
    padding: 0;
    padding-bottom: 1rem;
    border-radius: 0;
    border: 0 none;
    p {
      margin-bottom: 1rem;
      line-height: 1.4;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .read-more {
      cursor: pointer;
      color: $primary;
      font-family: $font-regular;
      @include fontSize(10px);
      @include media-breakpoint-down(sm) { @include fontSize(10px); }
      text-transform: uppercase;
      border: 0 none;
      &:after {
        content: "\f061";
        font-family: 'Font Awesome 5 Pro';
        position: relative;
        top: 0;
        left: 10px;
        font-size: 10px;
      }
      &:hover {
        color: $black;
        &::after {
          left: 12px;
        }
      }
    }
  }

  .mb-0:after {
    content: "\f078"; /* fa-chevron-down */
    font-family: 'Font Awesome 5 Pro';
    @extend .fal;
    position: absolute;
    top: 1.75rem;
    right: 0;
    font-size: 30px;
    @include media-breakpoint-down(sm) { font-size: 20px; }
  }
  .mb-0[aria-expanded="true"]:after {
    content: "\f077"; /* fa-chevron-up */
  }
  
}

.accordion > .card:first-of-type {
    border-bottom: 1px solid $black;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid $black;
    border-radius: 0;
}