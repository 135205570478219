.hero-resources__title {
    margin-top: 2rem;
    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }
}
.hero-related__title {
    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }
}