.video__player {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.video__player::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video__player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video__player + .wp-video {
    margin-top: 4rem;
}

.wp-video {
	border-radius: 1rem;
	overflow: hidden;
    margin-bottom: 4rem;
}

.wp-video, video.wp-video-shortcode, .mejs-container, .mejs-overlay.load {
	width: 100% !important;
}
.mejs-container {
	padding-top: 56.25%;
	border-radius: 1rem;
}
.mejs-controls {
	background: $primary !important;
}
.wp-video, video.wp-video-shortcode {
	max-width: 100% !important;
}
video.wp-video-shortcode {
	position: relative;
}
.mejs-mediaelement {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.mejs-controls {
	display: none;
}
.mejs-overlay-play {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: auto !important;
	height: auto !important;
}
.mejs-controls .mejs-time-rail .mejs-time-current {
    background: #212121 !important;
}