///Profile cards

.card-wrapper {
  background: $grey-lighter;
  display: flex;
  border-radius: $border-radius-lg;
  overflow: hidden;
  height: 100%;
}

// Profile
.card-profile {
  border: 0 none;
  border-radius: 0 none;
  background: transparent;

  &:hover {
    .card-header {
      &:before {
        left: 0;
      }
    }
    .card-header, .card-body {
      background: $grey-lightest;
    }
  }

  .card-header, .card-body, .card-footer {
    padding-left: 2rem;
    padding-right: 2rem;

    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .card-header {
    background: transparent;
    position: relative;
    border-bottom: 0;
    padding-top: 2rem;
    transition: .3s ease-in-out;
    display: flex;
    flex-flow: column wrap;

    .profile-title {
      margin-bottom: 0;
    }

    .profile-position {
      color: $primary;
      font-family: $font-regular;
      text-transform: uppercase;
      font-weight: 600;
      @include fontSize(14px);
    }

    .profile-company {
      color: $black;
      display: block;
      margin-top: .25rem;
      margin-bottom: 0;
      color: $black;
      font-weight: $fw-semi;
      letter-spacing: -0.05em;
      @include fontSize(14px);
    }

    .profile-company + .profile-company {
      padding-top: .75rem;
      margin-top: .75rem;
      margin-bottom: 1rem;
      border-top: 1px solid $white;
    }
  }

  .card-body {
    padding-top: 0;
    padding-bottom: 1.5rem;
    font-weight: normal;
    @include fontSize(14px);
    color: #2B2E33;
    line-height: 1.5rem;
    transition: .3s ease-in-out;
  }

  .card-footer {
    border: 0 none;
    background: transparent;
    padding-top: .5rem;
    padding-bottom: 2rem;
  }

  .read-more {
    cursor: pointer;
    color: $primary;
    font-weight: bold;
    text-decoration: underline;
    @include fontSize(14px);
    border: 0 none;
    &:after {
      content: "\f061";
      font-family: 'Font Awesome 5 Pro';
      position: relative;
      top: 0;
      left: 10px;
      font-size: 14px;
      transition: all .3s ease-in-out;
    }
    &:hover {
      color: $primary-dark;
      text-decoration: none;
      &::after {
        left: 12px;
      }
    }
  }

  .profile-linkedin {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    color: #151515;
    border: 1px solid #DCDCDC;
    border-radius: $border-radius-lg;
    transition: all .3s ease-in-out;
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}

a.card-profile {
  .card-header {
    &:before {
      content: "";
      left: -100%;
      top: 0;
      position: absolute;
      height: 2px;
      width: 100%;
      background: linear-gradient(90deg, #F39200 60.49%, #F0F2F7 100%);
      transition: .3s ease-in-out;
    }
  }
}

// Gallery

.card-gallery {
  border: 0 none;
  border-radius: 0 none;
  max-width: 450px;
  margin: 1rem auto;
  a {
    border: 0 none;
  }
  img {
    border-radius: $border-radius-md;
  }
  p {
    @include fontSize(14px);
  }
  .card-body {
    padding: 2.5rem 0;
  }
}

// News
.card--news {
  overflow: hidden;
  border-radius: $border-radius-lg;
  background: $grey-lighter;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  height: 100%;
  transition: all .3s ease-in-out;
  
  .bg-grey & {
    background: $white !important;
  }

  &:hover {
    color: $primary !important;
    background: $grey-lightest;
    .card--news__header {
      &:before {
        left: 0%;
      }
    }
  }
  &__header {
    position: relative;
    min-height: 450px;
    overflow: hidden;
    backface-visibility: hidden;
    img:first-of-type {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    &:before {
      content: "";
      left: -100%;
      bottom: 0;
      position: absolute;
      height: 2px;
      width: 100%;
      z-index: 1;
      background: linear-gradient(90deg, #F39200 60.49%, #F0F2F7 100%);
      transition: all .3s ease-in-out;
    }
    .related__sectors & {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 276px;
        width: 100%;
        background: linear-gradient(144.23deg, rgba(0, 0, 0, 0) 65.05%, #000000 101.72%);
      }
    }
  }
  .post-title {
    @include fontSize(24px);
    padding: 2rem 2rem 0 2rem;
    line-height: 2rem;
    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .post-date {
    margin-top: auto;
    padding-left: 2rem;
    padding-bottom: 2rem;
    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  p {
    padding-left: 2rem;
    padding-right: 2rem;
    color: $black;
    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .btn {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
  }
}



// Carousel

.slideshow-carousel {
  margin-top: 2.25rem;
  padding: 0 15px;
  @include media-breakpoint-down(sm) {
    padding: 0;
    margin: 0;
  }
  .slick-slide {
    margin: 0 15px;
    display: flex;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }
  .slick-list {
    // width: -webkit-fit-content;
    // width: -moz-fit-content;
    // width: fit-content;
    margin-bottom: 2rem;
    // display: flex;
  }
  .card--news {
    max-width: 450px;
    &__header {
      @include media-breakpoint-down(sm) {
        min-height: 220px;
      }
    }
  }
  .card {
    background-color: $grey-light;
    border-radius: 1rem;
    padding: 0;
    border: 0 none;
    max-width: 380px;
    max-width: 450px;
    margin-bottom: 2rem;

    &:hover {
      background-color: $grey-lighter;
    }
    
    @include media-breakpoint-down(sm) { margin-bottom:1.5rem; }

    .card-header {
        padding:0;
        margin-bottom: 0;
        background-color: transparent;
        border: 0 none;
        min-height: 11rem;
        padding: 2rem;
        @include media-breakpoint-down(sm) { min-height: 1rem; margin-right: 1rem; }
        
        h4 {
        	font-family: $font-regular;
          line-height: 2rem;
        	@include fontSize(24px);
          @include media-breakpoint-down(sm) { @include fontSize(20px); }
        	margin-top: 1rem;
        }
        .card-title {
          min-height: 6rem;
        }
        
    }
    .card-body {
        padding: 1rem 0 0 0;
        @include media-breakpoint-down(sm) { padding: 1rem 2rem 0 0; }
    }
    .card-date, .card-label {
      color: $primary;
      text-transform: uppercase;
      font-family: $font-bold;
      @include fontSize(14px);
      padding-bottom: 1rem;
    }
    .card-footer {
        padding: 0;
        background: transparent;
        border: 0 none;
        font-family: $font-regular;
        text-transform: uppercase;
        @include fontSize(10px);
        .separator {
        	padding: 0 0.25rem;
        }
        a, a:visited {
          border: 0 none;
        	color: $black;
        	i {
        		margin-left: 5px;
        	}
        }
        a:hover {
        	color: $primary;
        }
    }
  }
  .card--empty {
  	background-color: transparent;
  }

  	.slick-dots {
	    position: absolute;
	    bottom: -50px;
	    display: block;
	    width: 100%;
	    padding: 0;
	    margin: 0;
	    list-style: none;
	    text-align: center;

	    li {
		    position: relative;
		    display: inline-block;
		    width: 20px;
		    padding: 20px;
		    margin: 0 5px;
		    padding: 0;
		    cursor: pointer;

        &:before {
          display: none;
        }

		    button {
			    font-size: 0;
			    line-height: 0;
			    display: block;
			    width: 20px;
			    height: 20px;
			    padding: 5px;
			    cursor: pointer;
			    color: transparent;
			    border: 0;
			    outline: none;
			    background: transparent;

			    &:before {
			    	font-family: 'slick';
			    	font-size: 36px;
			    	line-height: 20px;
			    	position: absolute;
			    	top: 0;
			    	left: 0;
			    	width: 20px;
			    	height: 20px;
			    	content: '•';
			    	text-align: center;
			    	opacity: .25;
			    	color: $black;
			    	-webkit-font-smoothing: antialiased;
    				-moz-osx-font-smoothing: grayscale;
			    }
			}
		}

		li.slick-active button:before {
    		opacity: .75;
    		color: black;
		}
	}
}

.slider {
  width: 400px;
  background: black;
}
.slider .ui-slider-handle {
  display: block;
  width: 40px;
  height: 20px;
  margin-left: -20px;
  background: red;
  cursor: pointer;
  position: relative;
}

.slider-nav-thumbnails .slick-slide {
	opacity: 0;
  	max-width: 33% !important;
}

.slider-nav-thumbnails .slick-slide.slick-active {
	opacity: 1;
	background: red;
}

.slider-nav-thumbnails .handle {
	opacity: 1;
  	height: 20px;
  	background: red;
}

.slick-prev {
    left: 0;
    @include media-breakpoint-down(sm) { left: -25px; }
}
.slick-next {
    right: 0;
    @include media-breakpoint-down(sm) { right: -25px; }
}

.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.05);
    z-index:10;
    opacity:0.5;
}
.slick-prev:before {
    content: "\f053";
}

.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-family: 'Font Awesome 5 Pro';
    font-size: 22px;
    line-height: 1;
    opacity: .75;
    color: $black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    color: transparent;
    outline: none;
    background-color: rgba(0,0,0,0.05);
    opacity:1;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
    opacity: 1;
}
.slick-next:before {
    content: "\f054";
}
.slick-next.slick-disabled{
  right:-10000px;
}
.slick-prev.slick-disabled{
  left:-10000px;
}

// Sector cards

.card__service__link {
  background: $grey-lightest;
  border: none;
  border-radius: 1rem;
  overflow: hidden;
  p {
    font-size: .875rem;
  }
  .btn {
    width: 100%;
    margin-top: auto;
  }
}

.card__service__noLink {
  background: $grey-lightest;
  border: none;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;
}
.card__service__noLink__primary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.card__service__noLink__secondary {
  width: 100%;
  padding: 2rem 2rem 1rem;
  background: $grey-light;
}

.card__service__noLink__header {
  p {
    margin-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    width: 60%;
  }
}

.card__service__noLink__header, .card__service__link__header {
  padding: 2rem;
  display: flex;
  flex-flow: column;
  a {
    display: inline-block;
    margin-top: 1rem;
  }
  .post-title {
    display: flex;
    align-items: center;
    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }
    div {
        flex-shrink: 0;
    }
    svg {
        width: 100%;
        height: auto;
        max-width: 48px;
        margin-right: 1rem;
        path {
            fill: $black;
        }
        circle {
            stroke: $black;
        }

    }
  }
}
.card__service__contact {
  background: $grey-lighter;
  padding: 2rem;
  flex-flow: row wrap;
  margin-top: auto;
  .card__service__noLink & {
    background: $grey-lightest;
    width: 100%;
    @include media-breakpoint-down(md) {
      border-top: 2px solid $grey-light;
    }
    @include media-breakpoint-up(lg) {
      width: 40%;
    }
  }
}
.card__service__contact__name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}

.card__service__contact__position {
  text-transform: uppercase;
  font-size: .875rem;
  font-weight: $fw-semi;
  display: block;
  color: $primary;
}
.card__service__contact__avatar {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 1rem;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.card__service__contact__detail {
  margin-top: 1.125rem;
  &:last-of-type {
    margin-top: 0;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}