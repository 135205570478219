.site {
    overflow: hidden;
}
.wrapper {
	padding: 5rem 0;
	@include media-breakpoint-down(sm) { padding: 4rem 0; }
}

hr {
	border-top: 1px solid $black;
}

.page-wrapper {
	.featured-img {
		border-radius: $border-radius-lg;
		margin-top: -10rem;
		@include media-breakpoint-down(md) { margin-top: -5rem; }
	}
}

.wrapper-services--home {
	padding: 9rem 0 3rem;
	@include media-breakpoint-down(sm) { padding: 4rem 0 2rem; }
	h2.h1 {
		color: $primary;
	}
}
.wrapper.bg-shape-lg {
	padding-top: 5rem;
	@include media-breakpoint-down(sm) { padding-top: 2rem; }
}
.wrapper.bg-dark, .wrapper.bg-light {
	padding-top: 0;
	padding-bottom: 0;
}
.wrapper--card-carousel {
	padding: 9rem 0;
	@include media-breakpoint-down(sm) { padding: 4rem 0; }
}

.wrapper.bg-dark.wrapper-split-content {
	padding: 0;
}

.single-services-wrapper {
	.featured-img {
		border-radius: $border-radius-lg;
		margin-top: -10rem;
		@include media-breakpoint-down(md) { margin-top: -5rem; }
	}
	.site-main {
		> p:first-of-type{
			font-weight: bold;
			font-size: 150%;
    		font-family: $font-regular;
		}
	}

	.page-template-careers & {
		padding: 0;
	}
}

.careers__slider {
	padding: 7.5rem 0;

	.careers__slider__wrapper {
		max-width: 894px;
	}

	.careers__slider__title {
		font-size: 3.5rem;
		line-height: 4.5rem;
		margin-bottom: 1rem;

		@include media-breakpoint-down(md) { 
			font-size: 2.5rem;
			line-height: 3rem;
		}
	}

	.careers__slider__description,
	.careers__slider__description p {
		font-size: 1.25rem;
		line-height: 2rem;
		margin-bottom: 2.5rem;
	}

	.container__slider {
		position: relative;
		&:after {
			content: '';
			display: inline-block;
			background: linear-gradient(270deg, #FFFFFF 4.96%, rgba(255, 255, 255, 0) 77.69%);
			position: absolute;
			top: 0;
			right: 0;
			width: 300px;
			height: 100%;
			pointer-events: none;

			@include media-breakpoint-down(md) { 
				display: none;
			}
		}
	}

	.careers__slider__items {
		display: flex;
		margin-bottom: 5.5rem;
		
		@include media-breakpoint-down(md) { 
			margin-bottom: 5rem;
		}

		.slick-list{
			@include media-breakpoint-up(xl) { 
				padding:0 20% 0 0;
			}
		}

		.slick-slide {
			margin: 0 .5rem;

			@include media-breakpoint-up(xl) { 
				min-width: 1000px;
			}

			.inner {
				display: flex !important;
				align-items: center;
				gap: 5rem;

				@include media-breakpoint-down(md) { 
					min-width: inherit;
					gap: 2.5rem;
				}

				@include media-breakpoint-down(sm) { 
					flex-direction: column;
				}

				@include media-breakpoint-up(xl) { 
					min-width: 1000px;
				}
			}
		}
	}

	.careers__slider__item {
		background-color: #F7F8FC;
		border-radius: 1rem;
		display: flex !important;
		align-items: center;
		gap: 5rem;
		padding: 3rem 4rem;

		@include media-breakpoint-down(md) { 
			padding: 2.5rem 3rem;
		}

		.right {
			@include media-breakpoint-down(md) { 
				flex: 1;
			}
		}

		.left {
			display: flex;
			flex-direction: column;
			align-items: center;

			@include media-breakpoint-down(md) { 
				flex: .7;
			}

			img {
				width: 180px;
				height: 180px;
				object-fit: cover;
				border-radius: 100%;
				margin-bottom: 1.5rem;
			}
		}

		.name {
			font-size: 20px;
			font-weight: 600;
			line-height: 24px;
			text-align: center;
		}

		.role {
			width: 60%;
			font-size: 12px;
			font-weight: 600;
			line-height: 24px;
			text-align: center;
			text-transform: uppercase;
		}

		.right {
			font-size: 20px;
			font-weight: 600;
			line-height: 32px;
			max-width: 579px;
			position: relative;

			@include media-breakpoint-down(xl) { 
				flex: 1;
			}

			svg {
				width: 2rem;
				height: 1.375rem;
				position: absolute;
				top: -1.375rem;
				left: -2rem;
			}
		}
	}

	.cta {
		display: flex;
		justify-content: space-between;
		gap: 2rem;

		@include media-breakpoint-down(md) { 
			flex-direction: column;
			gap: 4rem;
		}

		.left {
			@include media-breakpoint-down(md) { 
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}

		.title {
			font-size: 2.5rem;
			line-height: 4.5rem;
		}

		.careers__slider_images {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 1rem;

			@include media-breakpoint-down(xxl) { 
				justify-content: space-evenly;
				max-width: 55%;
				margin: auto;
			}

			@include media-breakpoint-down(md) { 
				max-width: 80%;
			}

			// @include media-breakpoint-up(xl) { 
			// 	justify-content: space-evenly;
			// 	max-width: 80%;
			// 	margin: auto;
			// }

			img {
				width: 150px;
				height: 150px;
				object-fit: cover;
				border-radius: 100%;
			}
		}

		.btn {
			max-width: 300px;

			@include media-breakpoint-down(lg) { 
				margin: auto;
			}	
		}
	}
}

.offices {
	padding: 7.5rem 0;

	.offices__wrapper {
		max-width: 894px;
	}

	.offices__title {
		font-size: 3.5rem;
		line-height: 4.5rem;
		margin-bottom: 1rem;
	}

	.offices__description,
	.offices__description p {
		font-size: 1.5rem;
		line-height: 2.25rem;
		margin-bottom: 3.5rem;
		font-weight: 600;
	}

	.offices__items {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 3.875rem;
		// margin-bottom: 5.5rem;
		@include media-breakpoint-down(lg) { 
			grid-template-columns: 1fr 1fr; 
		}

		@include media-breakpoint-down(md) { 
			grid-template-columns: 1fr; 
		}
	}

	.offices__item {
		border-radius: 1rem;
		display: flex !important;
		align-items: center;
		gap: 5rem;
		position: relative;
		height: 320px;
		border-radius: 1rem;
		overflow: hidden;

		&:after {
			background: linear-gradient(260.55deg, rgba(0, 0, 0, 0) 68.1%, #000000 97.09%);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
			display: inline-block;
			pointer-events: none;
		}

		&:hover {
			.link {
				color: $white;
				background-color: $black;
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		a {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 2;
		}

		.link {
			position: absolute;
			bottom: 1.5rem;
			right: 2rem;
			border-radius: 2rem;
			background: $primary;
			color: $white;
			text-transform: uppercase;
			padding: 2px 12px;
			font-size: 10px;
			min-height: 28px;
			display: flex;
			font-weight: bold;
			align-items: center;
			transition: all .15s ease-in-out;
			border: 1px solid $primary;
			cursor: pointer;

			&:hover {
				color: $white;
				background-color: $black;
			}
		}

		.footer {
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			letter-spacing: -0.05em;
			text-align: left;
			position: absolute;
			color: $white;
			bottom: 1.5rem;
			left: 2rem;
			display: flex;
			flex-direction: column;
			z-index: 2;
		}
	}
}

.latest_vacancies {
	background: #212121;
	padding: 7.5rem 0;
	position: relative;

	@include media-breakpoint-down(md) { 
		padding: 5rem 0;
	}

	.shape {
		position: absolute;
		left: 0;
		top: 0;
		pointer-events: none;

		@include media-breakpoint-down(md) { 
			display: none;
		}
	}

	&__row {
		display: flex;
		gap: 2rem;

		@include media-breakpoint-down(lg) { 
			flex-direction: column;
		}

		.right {
			background-color: $white;
			padding: 4rem 2rem;
			border-radius: .5rem;
			display: flex;
			align-items: center;
			font-size: 1rem;
			flex: 1;

			@include media-breakpoint-down(lg) { 
				padding: 2rem;
			}

			p {
				font-size: 1rem;
				margin-bottom: 0;

				@include media-breakpoint-down(md) { 
					text-align: center;
				}
			}

			a {
				text-decoration: underline;
			}
		}
	}

	.latest_vacancies__wrapper {
		@include media-breakpoint-up(xl) { 
			max-width: 889px;
		}
	}

	.latest_vacancies__title {
		font-size: 3.5rem;
		line-height: 4.5rem;
		margin-bottom: 1rem;
		color: $white;

		@include media-breakpoint-down(md) { 
			font-size: 2.5rem;
			line-height: 3rem;
		}
	}

	.latest_vacancies__description,
	.latest_vacancies__description p {
		font-size: 1.5rem;
		line-height: 2.25rem;
		margin-bottom: 2.5rem;
		font-weight: 600;
		color: $white;

		@include media-breakpoint-down(lg) { 
			margin-bottom: 0;
			font-size: 1.25rem;
			line-height: 2rem;
		}
	}

	.jobs-list {
		color: $white;
		display: flex;
		flex-direction: column;
		gap: 3.5rem;
		margin-top: 5rem;

		@include media-breakpoint-down(lg) { 
			margin-top: 3.75rem;
		}
		
		&__row {
			display: flex;
			justify-content: space-between;
			gap: 4rem;

			@include media-breakpoint-down(lg) { 
				flex-direction: column;
				gap: .5rem;
			}

			.left {
				flex: 1;
				display: flex;
				align-items: center;
				gap: 1rem;
			}
	
			.right {
				@include media-breakpoint-up(lg) { 
					flex: 2;
				}
				@include media-breakpoint-up(xl) { 
					max-width: 889px;
				}
			}
		}

		.company {
			font-size: 40px;
			font-weight: 600;
			line-height: 56px;
			letter-spacing: -0.05em;
			text-align: left;
			margin-bottom: 0;
		}

		.quantity {
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			text-align: left;
			color: $primary;
			border: 1px solid #F39200;
			border-radius: 2rem;
			padding: 0 .8rem;
		}

		&__list {
			display: flex;
			flex-flow: row wrap;
			gap: 1rem;
			list-style: none;
			margin-top: 1rem;
			margin-bottom: 0;

			li {
				padding: 0;
				&:before {
					display: none;
				}

				a {
					background-color: $primary;
					border: 1px solid $primary;
					color: $white;
					font-size: .875rem;
					font-weight: 600;
					border-radius: 2rem;
					display: flex;
					align-items: center;
					padding: 0 12px;
					height: 28px;
					text-transform: uppercase;
					text-wrap: nowrap;
					transition: all .4s ease-in-out;

					@include media-breakpoint-down(lg) { 
						text-wrap: wrap;
					}

					@include media-breakpoint-down(sm) { 
						height: auto;
						padding: 6px 12px;
					}

					&:hover {
						background-color: $black;
					}

					&:after {
						pointer-events: none;
						content: "";
						margin-left: .5rem;
						transition: all .2s ease-in-out;
						width: 16px;
						height: 28px;
						background-repeat: no-repeat;
						background-image: url("data:image/svg+xml,%3Csvg width='21' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.59 1.164l-.375.328a.635.635 0 000 .797l3.937 3.89H.574a.57.57 0 00-.562.563v.469c0 .328.234.562.562.562h17.578l-3.937 3.938a.635.635 0 000 .797l.375.328c.187.234.562.234.797 0l5.437-5.438a.54.54 0 000-.796l-5.437-5.438c-.235-.234-.61-.234-.797 0z' fill='%23fff'/%3E%3C/svg%3E");
						background-size: contain;
						background-position: center;
					}
				}
			}

			.no-vacancies {
				background-color: #545454;
				border: 1px solid #545454;
				color: $white;
				font-size: .875rem;
				font-weight: 600;
				border-radius: 2rem;
				display: flex;
				align-items: center;
				padding: 0 12px;
				min-height: 28px;
				text-transform: uppercase;
				text-wrap: nowrap;
				transition: all .4s ease-in-out;
				gap: .5rem;

				@include media-breakpoint-down(lg) { 
					text-wrap: wrap;
				}

				&:hover {
					background-color: $black;
				}
			}
		}
	}
}

.single-gallery-wrapper {
	.title-container {
		text-align: center;
		padding-bottom: 6rem;
		h1 {
			color: $black;
		}
	}
}

.recruitment-wrapper {
	padding-top: 6rem;
	h1 {
		font-family: $font-regular;
		margin-top: 0;
		color: $black;
		font-weight: 600;
	}
	.main-content {
		width: 100%;
		> p:first-of-type{
			font-weight: bold;
			font-size: 150%;
    		font-family: $font-regular;
		}
	}
}

.clients-wrapper {
	padding: 5rem 15px;
	.client-logo {
		padding: 2rem;
    	min-width: 33%;
		img {
			display: block;
			margin: 0 auto;
		}
	}
}

.site-main {
	
	figure {
		margin-bottom: 2.5rem;
	}
	img {
		border-radius: $border-radius-lg;
	}
	p img {
		margin: 0.75rem auto;
	}
	.wp-caption-text {
		font-family: $font-regular;
		@include fontSize(12px);
		text-transform: uppercase;
		border-bottom: 1px solid $black;
		padding: 1rem 0;
		@include media-breakpoint-down(sm) { @include fontSize(10px); padding: 0.5rem 0; }
	}
	> p:first-of-type{
		font-weight: bold;
		font-size: 150%;
		font-family: $font-regular;
	}
}

.main--offset {
	margin-left: 12.5%;
	@include media-breakpoint-down(lg) { margin-left: 0; }
}


.contact-wrapper {
	padding-top: 6rem;
	h1 {
		margin-bottom: 1.125rem;
	}
	@include media-breakpoint-down(md) {
		padding: 3rem 0;
	}
}

.info-wrapper {
	padding: 2rem 0 5rem 0;
}

.wrapper.latest-news-wrapper,
.wrapper.about-us-wrapper,
.wrapper.testimonials-wrapper {
	padding: 6rem 0;
	@include media-breakpoint-down(md) {
		padding: 3rem 0;
	}
}

.wrapper.about-us-wrapper {
	.page-template-careers & {
        padding: 6rem 0 3rem;
		@include media-breakpoint-down(md) {
			padding: 3rem 0;
		}
    }
}


.wrapper.about-us-wrapper {
	.page-template-sector & {
		@include media-breakpoint-down(sm) {
			padding-top: 0;
		}
	}
}

.wrapper.latest-news-wrapper {

	.site-main > p:first-of-type{
		font-weight: normal;
		@include fontSize(16px);
		font-family: $font-regular;
	}

}

/// light box modal

.lightbox-modal {
	.modal-dialog {
      	max-width: 800px;
      	margin: 30px auto;
  	}
  	.modal-content{
  		border: 0 none;
  	}
  	.modal-body {
	  position:relative;
	  padding:0px;
	  min-height:50px;
	  background: transparent;
	}
	.close {
	  position:absolute;
	  right:0;
	  top:0;
	  z-index:999;
	  font-size:1.5rem;
	  font-weight: normal;
	  color:#fff;
	  opacity:1;
	}
}


.google-map {
	width: 100%;
	height: 450px;
	background-color: $black;
}

.page-id-503 {
	.full-width-page-wrapper {
		display: none;
	}
}

.main-content {
	width: 100%;
}