.testimonials__grid {
    @include media-breakpoint-up(lg) {
        margin-top: 2rem;
    }
    blockquote p {
        position: relative;
        &:before {
            left: -2.6rem;
            top: 0;
            color: #DC7200;
            font-size: 3.5rem;
        }
    }
}
.testimonial-block {
    width: 50%;
    margin-bottom: 1rem;

    p {
       font-size: 1rem !important;
       line-height: 1.625rem !important;
    }
    @include media-breakpoint-down(md) {
        width: 100%;
        margin: 0 0 .5rem 0 !important;
    }
}

.testimonial-block__inner {
    background: $grey-lighter;
    padding: 3.5rem 3.5rem 1rem 3.5rem;
    margin: 1rem;
    border-radius: 1rem;
    @include media-breakpoint-down(md) {
        padding: 1.5rem;
        margin: .5rem 0;
    }
}
.testimonial-block--full {
    width: 100%;
    @include media-breakpoint-up(lg) {
        .testimonial-block__inner {
            position: relative;
            background: none;
            &:before {
                content: "";
                background: #F0F2F7;
                width: 110%;
                height: 100%;
                position: absolute;
                left: -50%;
                top: 0;
                border-top-right-radius: 1rem;
                border-bottom-right-radius: 1rem;
                z-index: -1;
            }
            &:after {
                content: "";
                height: 100%;
                width: 1px;
                position: absolute;
                left: 3.5%;
                top: 0;
                background: $primary-light;
                box-sizing: border-box;
                animation: line ease-in-out 1.5s forwards;
                z-index: -1;
            }
            p {
                position: relative;
                max-width: 80%;
                span {
                    position: relative;
                    display: block;
                    z-index: 9;
                    transform: translateX(-40px);
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
                &:before {
                    content: "";
                    background: #F0F2F7;
                    width: 80px;
                    height: 100%;
                    position: absolute;
                    left: -40px;
                    top: 0;
                }
            }
            .blockquote-footer {
                padding-left: 4rem;
            }
        }
    }
}
.grid-sizer { width: 50%; }
.gutter-sizer { width: 2%; }

.blockquote-footer::before {
    display: none;
}

