.hide--mobile {
    @include media-breakpoint-down(md) {
        display: none;
    }
}
.hide--desktop, .collapse-desktop {
    flex-grow: 0 !important;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

// Navigation version specific

.services-menu--active {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0, 0.6);
    }
}
.services-menu {
    position: relative;
    z-index: -1;
    transition: all .3s ease-in-out;
    transform: translateY(-150px);
    opacity: 0;
    display: none;
    pointer-events: none;
    @include media-breakpoint-down(md) {
        display: none;
    }
    .services-tabs__group {
        background: rgba(33, 33, 33, 1);
    }
}
.services-menu--show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    pointer-events: initial;
    z-index: 999;
    display: block;
}


// In-page version

.services-group {
    background-color: $black;
    background-image: url('../img/sectors_bg.png');
    background-repeat: none;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    @include media-breakpoint-down(lg) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    } 
    @include media-breakpoint-up(lg) {
        padding-top: 7.5rem;
        padding-bottom: 7.5rem;
    }
}
.services-group__heading {
    padding-bottom: 2rem;
    margin-bottom: 0;
}

.services-tabs {
    position: relative;
    display: flex;
    width: 100%;
    border-radius: .5rem .5rem 0 0;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 1);

    @include media-breakpoint-down(sm) {
        flex-wrap: no-wrap;
        overflow-x: auto;
    }
}

.services-tabs__item {
    padding: 1rem 1.5rem;
    color: $white;
    font-weight: $fw-semi;
    transition: all .3s ease-in-out;
    border: none;
    background: rgba(33, 33, 33, 1);
    white-space: nowrap;
    text-transform: uppercase;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 1);

    @include media-breakpoint-down(md) {
        justify-content: center
    }
    @include media-breakpoint-down(lg) {
        @include fontSize(16px);
    }

    @include media-breakpoint-up(lg) {
        @include fontSize(18px);
    }

    &:hover {
        color: $primary-light;
        span {
            color: $white;
        }
    }

    &:last-of-type {
        border-right: none;
    }

    span {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        justify-content: center;
        border-radius: 50px;
        align-items: center;
        background: $primary;
        border: 1px solid transparent;
        transition: all .3s ease-in-out;
        @include media-breakpoint-down(md) {
            display: none;
        }
        @include media-breakpoint-only(lg) {
            border: none;
            background: none;
            width: 1rem;
            height: 1rem;
        }
    }
}

.services-tabs__item.active {
    color: $white;
    background: $primary;
    span {
        transform: rotate(180deg);
        border: 1px solid $white;
        @include media-breakpoint-only(lg) {
            border: none;
        }
    }
    &:hover {
        color: $white;
    }
}

.services-tabs__list {
    display: flex;
    flex-flow: row wrap;
    @include media-breakpoint-down(md) {
        flex-basis: 100%;
    }
}
.services-tabs__list__item {
    color: $white !important;
    text-decoration: underline;
    margin-bottom: 1.5rem;
    &:hover {
        text-decoration: none;
    }
    @include media-breakpoint-down(md) {
        flex-basis: 100%;
    }
    @include media-breakpoint-between(md, xl) {
        flex-basis: calc(50% - .7em);
    }
    @include media-breakpoint-up(xxl) {
        flex-basis: calc(33.333333% - .7em);
        padding-right: 1rem;
    }
}

.services-buttons {
    display: flex;
    flex-flow: row wrap;

    @include media-breakpoint-down(sm) {
        margin-top: 2rem;
    }

    .btn {
        min-height: 72px;
        display: flex;
        align-items: center;
        font-size: 1rem;
        animation: slideEffectSmall ease-in-out .3s forwards;
        opacity: 0;
        @include buttonIcon;

        @include media-breakpoint-down(sm) {
            min-height: 48px;
            width: 100%;
            margin-bottom: .5rem;
        }
        @include media-breakpoint-up(md) {
            width: 48%;
            margin: 1%;
        }
    }
  }

@for $i from 1 through 99 {
    .services-buttons .btn:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.2}s;
    }
}

.services-parent {
    display: flex;
    align-items: center;
    max-width: 348px;
    max-height: 348px;
    margin-right: auto;
    @include media-breakpoint-down(sm) {
        display: none;
    }
}
.services-parent__thumbnail {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;

        &.thumbnail {
            border-radius: .5rem;
        }

        @include media-breakpoint-down(lg) {
            &.thumbnail {
                display: none;
            }
        }
    }
}
.services-parent__logo {
    position: absolute;
    bottom: 1.25rem;
    left: 1.25rem;
    img {
        max-width: 108px;
        height: auto;
        image-rendering: -webkit-optimize-contrast;
    }

    animation: slideEffect .3s;

    @include media-breakpoint-down(lg) {
        position: relative;
        bottom: inherit;
        left: inherit;
    }
}

.services-parent__description {
    @include fontSize(18px);
    margin-bottom: 3rem;
    animation: slideEffect .4s;
    max-width: 783px;

    @include media-breakpoint-down(sm) {
        font-size: 1.0625rem;
        line-height: 1.6rem;
    }
    @include media-breakpoint-between(md, xl) {
        padding-right: 2rem;
    }

    &.name {
        @include fontSize(24px);
        font-weight: bold;

        span {
            margin-right: 1rem;
            text-decoration: underline;
        }

        a {
            &:hover {
                span {
                    text-decoration: none;
                }

                i {
                    margin-left: 10px;
                }
            }

            i {
                transition: all .3s ease-in-out;
                margin-left: 0;
            }
        }
    }
}

.services-tabs__group {
    animation: fadeEffect .5s;
    padding: 2.5rem 1rem;
    border-radius: 0 0 .5rem .5rem;
    overflow: hidden;
    background: rgba(33, 33, 33, 1);
    border: 1px solid rgba(0, 0, 0, 1);
    border-top-width: 0;
    position: relative;

    @include media-breakpoint-between(md, xl) {
        padding: 4rem 2rem;
    }
    @include media-breakpoint-up(xl) {
        padding: 4rem;
    }
    .btn {
        width: 100%;
    }

    &:after {
        display: inline-block;
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        background-image: url(../img/services-decor.svg);
        width: 214.19px;
        height: 267px;
    }
}

@keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}
@keyframes slideEffect {
    from {
        transform: translateX(-100px);
        opacity: 0;
    }
    to {
        transform: translateX(0px);
        opacity: 1;
    }
}
@keyframes slideEffectSmall {
    from {
        transform: translateX(-16px);
        opacity: 0;
    }
    to {
        transform: translateX(0px);
        opacity: 1;
    }
}