.careers__intro {
    @include media-breakpoint-down(md) {
        margin-bottom: -1rem;
    }
    p.lead {
        margin-bottom: 2rem;
        max-width: 100%;
    }
    p.narrower {
        max-width: 633px;
        font-size: 1rem !important;
        margin-bottom: 2.5rem;
    }
}

.featured-careers {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: auto;
}
.featured-careers__card {
    background: $white;
    display: block;
    padding: 1.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    transition: all .3s ease-in-out;
    &:hover {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.02);
        transform: translateY(1px);
    }
}

.featured-careers__card h3 {
    color: $black;
    @include fontSize(24px);
    line-height: 2rem;
    letter-spacing: -0.03em;
}

.featured-careers__card ul {
    @include fontSize(14px);
    margin-bottom: 0;
    color: $black;
    li {
        padding-left: 0;
        &:before {
            display: none;
        }
    }
}