
.site-main {
  .blockquote, blockquote {
    p {
      @include fontSize(48px);
      @include media-breakpoint-down(sm) {  @include fontSize(30px); }
      line-height: 1.2;
    }
  }
}

.aside--testimonials {
    padding: 2rem 1rem;
    position: relative;
  
    &:before {
      content: "";
      position: absolute;
      right: -125%;
      top: 0;
      width: 200%;
      height: 100%;
      background: $grey-lighter;
      border-radius: 1rem 0 0 1rem;
    }

    h3 {
      @include fontSize(36px);
      @include media-breakpoint-down(sm) {
          @include fontSize(24px);
        }
    }

  }
  // Homepage Carousel
  
.carousel-controls {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    padding: 4rem .5rem 4rem;
    width: unset !important;
    @include media-breakpoint-down(md) {
        padding: 2rem .5rem 1rem
    }
    .carousel-control-prev, .carousel-control-next {
      position: unset;
      width: 40px;
    }
    .carousel-control-next {
      opacity: 1;
      &:hover {
        .carousel-control-next-icon {
          background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 14'%3E%3Cpath d='M13.828.438l-.937.937a.583.583 0 00.046.797l3.75 3.61H.563A.57.57 0 000 6.343v1.312c0 .328.234.563.563.563h16.125l-3.75 3.656a.583.583 0 00-.047.797l.937.937a.635.635 0 00.797 0l6.188-6.187a.635.635 0 000-.797L14.625.437a.635.635 0 00-.797 0z' fill='%23000000'/%3E%3C/svg%3E");
        }
      }
    }
    .carousel-control-next-icon {
      transition: all .3s ease-in-out;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 14'%3E%3Cpath d='M13.828.438l-.937.937a.583.583 0 00.046.797l3.75 3.61H.563A.57.57 0 000 6.343v1.312c0 .328.234.563.563.563h16.125l-3.75 3.656a.583.583 0 00-.047.797l.937.937a.635.635 0 00.797 0l6.188-6.187a.635.635 0 000-.797L14.625.437a.635.635 0 00-.797 0z' fill='%23DC7200'/%3E%3C/svg%3E");
    }
    .carousel-control-prev {
      opacity: 1;
      &:hover {
        .carousel-control-prev-icon {
          background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 14'%3E%3Cpath d='M7.125 13.61l.938-.938a.583.583 0 00-.047-.797l-3.75-3.656h16.172A.57.57 0 0021 7.656V6.344c0-.282-.281-.563-.563-.563H4.267l3.75-3.61a.583.583 0 00.046-.796L7.125.437a.635.635 0 00-.797 0L.141 6.625a.635.635 0 000 .797l6.187 6.187a.635.635 0 00.797 0z' fill='%23000000'/%3E%3C/svg%3E");
        }
      }
    }
    .carousel-control-prev-icon {
      transition: all .3s ease-in-out;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 14'%3E%3Cpath d='M7.125 13.61l.938-.938a.583.583 0 00-.047-.797l-3.75-3.656h16.172A.57.57 0 0021 7.656V6.344c0-.282-.281-.563-.563-.563H4.267l3.75-3.61a.583.583 0 00.046-.796L7.125.437a.635.635 0 00-.797 0L.141 6.625a.635.635 0 000 .797l6.187 6.187a.635.635 0 00.797 0z' fill='%23DC7200'/%3E%3C/svg%3E");
    }
  }

// New Carousel (using Slick)

.testimonials-carousel__item {
  max-width: 960px;
  overflow: unset; 
}

.testimonials-carousel__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 700px;
  @include media-breakpoint-down(sm) {
      flex-flow: column;
  }
  .blockquote {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 1rem;
    height: 100%;
    display: flex;
    flex-flow: column;
    footer {
      margin-top: auto;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
    p {
        @include fontSize(16px);
        padding-left: 3.5rem;
        line-height: 1.425;
        font-family: $font-regular;
        margin-bottom: 0;
        &:before {
          left: 0;
        }
        @include media-breakpoint-down(sm) {
          padding-left: 0;
        }
      }
      @include media-breakpoint-down(sm) {
          margin: 0;
      }
  }
}

.testimonials-carousel__item__logo {
  max-width: 160px;
  padding: 1rem;
  flex-shrink: 0;
  margin-left: 1rem;
  @include media-breakpoint-down(sm) {
      padding: 0;
      margin-left: 0;
  }
}

.testimonials-carousel {
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
    display: flex !important;
    > div {
      height: inherit !important;
      > div {
        height: 100% !important;
        display: flex;
      }
    }
  }
  .slick-next {
    right: 0;
    bottom: 0;
    top: unset;
  }
  .slick-prev {
    right: 3rem;
    left: unset;
    bottom: 0;
    top: unset;
  }
}