.wrapper-hero {
  padding: 15rem 0 10rem 0;
  background-size: cover; 
  background-repeat: no-repeat; 
  background-position: top center;
  color: $white;
  margin-top: -150px;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }

  h1 {
    color: $white;
    @include fontSize(60px);
    font-family: $font-regular; 
    line-height: 1.2;
    margin-bottom: 1.125rem;
    max-width: 920px;
    @include media-breakpoint-down(md) {
      @include fontSize(48px);
    }
    @include media-breakpoint-down(sm) {
      @include fontSize(36px);
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 3rem;
    }
  }

  p {
    color: $white;
    &.preheader {
      font-family: $font-regular; 
      text-transform: uppercase;
      @include fontSize(14px);
      margin-bottom: 1.5rem;
      letter-spacing: -0.2px;
    }
  }

  &.hero--home {
    padding: 27.3rem 0 7.5rem 0;
    max-height: 840px;
    position: relative;

    @keyframes zoomies {
      0% {
          transform: scale(1.0);
      }
      100% {
          transform: scale(1.15);
      }
  }
    @include media-breakpoint-down(sm) {
      padding: 10rem 0 4rem;
    }
    @include media-breakpoint-up(lg) {
      overflow: hidden;
    }

    .lead {
      margin-bottom: 0;
    }

    .hero-btn-container {
      @include media-breakpoint-down(sm) { 
        text-align: left;
      }

      .btn-primary {
        @include media-breakpoint-down(sm) {
          margin-bottom: 1rem;
          width: 100%;
        }
        @include media-breakpoint-up(md) { 
          margin-right: 1rem;
        }
      }

      .btn {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

  }
  &.hero--general {
    background-image: url('../img/hero/hero2.jpg'); 
  }

}

.hero-default {
  background-color: $black;
  background-image: url('../img/header_bg.jpg'); 
  background-size: cover;
  height: 150px;
  background-position: top right;
  position: absolute;
  position: relative;
  width: 100%;
  z-index: -1;
  @include media-breakpoint-down(md) { display: none; }
}

.text-hero {
  margin-bottom: 1rem;
  @include media-breakpoint-only(md) {
    margin-bottom: 3rem;
  }
  @include media-breakpoint-up(lg) {
    max-width: 804px;
    margin-bottom: 5rem;
  }
  h1 {
    max-width: 620px;
    @include media-breakpoint-between(md, lg) {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
  .btn {
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
    @include media-breakpoint-up(sm) {
      min-width: 304px;
    }
  }
}

.text-hero__title {
  text-transform: uppercase;
  color: $primary !important;
  @include fontSize(14px);
  font-weight: 600;
}

.text-hero__link {
  text-transform: uppercase;
  background-color: $primary !important;
  color: $white;
  @include fontSize(14px);
  font-weight: 600;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin: 0 0 1.5rem 1rem;
  min-height: 28px;
  border: 1px solid $primary;
  transition: all .15s ease-in-out;

  &:hover {
    color: $primary;
    background-color: transparent !important;
  }
}

.text-hero__recruitment {
  p {
    &:first-of-type {
      @include fontSize(20px);
      line-height: 2rem;
      @include media-breakpoint-down(sm) { 
        @include fontSize(18px); 
        line-height: 1.6rem;
      }
    }
    &:last-of-type {
      @include fontSize(14px);
    }
  }
}

.hero--home {
  position: relative;
  .btn {
    min-width: 304px;
    @include media-breakpoint-down(sm) {
      max-width: 375px;
    }
  }
  &:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='506' height='620' viewBox='0 0 506 620' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2025_6733)' fill='%23F39200'%3E%3Cpath opacity='.3' d='m531.803 272.034-876.09 590.105L-594 916.546l1116.038-92.073 9.765-552.439Z'/%3E%3Cpath opacity='.3' d='M515.062 186.936 100.733 677.993-594 849.584l1116.038-92.073-6.976-570.575Z'/%3E%3Cpath opacity='.3' d='M520.643 0 316.966 679.388l-844.004 170.196L589 757.511 520.643 0Z'/%3E%3Cpath d='M522.038 414.329-594 857.954v29.994H522.038V414.329Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2025_6733'%3E%3Cpath fill='%23fff' d='M0 0h506v620H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    width: 506px;
    height: 620px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    @include media-breakpoint-down(sm) {
      height: 80%;
    }
  }
}

// Hero Image - Home

.hero--home__image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: -1;
  img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
  }
  @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 3rem;
  }
  @include media-breakpoint-up(lg) {
    animation: zoomies 15s ease-in-out infinite alternate;
    max-height: 840px;
    min-height: 840px;
  }
}