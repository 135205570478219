.sector__intro {
    background: $black;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    display: flex;
    align-items: center;
    padding: 3.5rem 0;
    position: relative;
    margin-bottom: 3rem;

    @include media-breakpoint-down (md) {
        flex-flow: column;
        text-align: center;
    }

    &:before {
        content: "";
        background: $black;
        width: 200%;
        left: -200%;
        height: 100%;
        position: absolute;
        top: 0;
        @include media-breakpoint-down(xs) {
            left: -30px;
            z-index: -1;
        }
    }
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 752 217'%3E%3Cg opacity='.05' fill-rule='evenodd' clip-rule='evenodd' fill='%23fff'%3E%3Cpath d='M136.194.003c-16.842-.219-30.677 13.301-30.895 30.184V59.87c-.109 18.522-15.186 33.417-33.662 33.308H30.916C14.075 92.96.24 106.458 0 123.341v63.209c.065 16.884 13.791 30.513 30.633 30.447h73.729c16.842.219 30.677-13.279 30.917-30.163v-29.791c.13-18.522 15.186-33.418 33.661-33.309h40.656c16.842.219 30.677-13.279 30.916-30.163V30.362c-.109-16.883-13.878-30.49-30.72-30.36h-73.598zM359.761.003c16.876-.219 30.739 13.301 30.957 30.184V59.87c.066 18.522 15.13 33.461 33.643 33.396H465.252c16.876-.219 30.74 13.301 30.958 30.184v63.1c-.065 16.884-13.82 30.513-30.696 30.447h-73.835c-16.876.219-30.739-13.301-30.958-30.185V157.043c-.109-18.522-15.217-33.418-33.73-33.309h-40.804c-16.876.219-30.739-13.279-30.979-30.163V30.362c.131-16.883 13.907-30.49 30.783-30.36h73.77zM647.089.003C630.246-.216 616.41 13.309 616.192 30.2v29.694c-.109 18.529-15.187 33.43-33.665 33.322H541.803c-16.844-.219-30.68 13.306-30.898 30.196v63.126c.065 16.89 13.793 30.524 30.636 30.459h73.67c16.844.24 30.68-13.285 30.92-30.175v-29.717c.109-18.528 15.187-33.43 33.664-33.321h40.725c16.843.218 30.68-13.307 30.898-30.197V30.374c-.131-16.89-13.88-30.502-30.724-30.371h-73.605z'/%3E%3C/g%3E%3C/svg%3E");
    }
}
.sector__intro__logo {
    img {
        max-width: 144px;
        height: auto;
    }
    @include media-breakpoint-up (lg) {
        margin-right: 2rem;
    }
}
.sector__intro__copy {
    margin-bottom: 0;
    color: $white;
    font-size: 2rem;
    line-height: 2.7rem;
    @include media-breakpoint-down(md) {
        margin-top: 1rem;
        font-size: 1.6rem;
        line-height: 2.25rem;
    }
    @include media-breakpoint-up(md) {
        padding-right: 1rem;
    }
}

.page-template-sector {
    .text-hero h3 {
        line-height: 2.5rem;
        font-size: 1.5rem;
        @include media-breakpoint-down(md) {
            font-size: 1.25rem;
            line-height: 2rem;
        }
    }
}

.related__sectors {
    padding-top: 3rem;
    padding-bottom: 2rem;

    h3 {
        font-size: 3rem;
    }

}
.related__sectors__sector {
    margin-bottom: 2rem;
}

.sector_logo {
    max-width: 124px;
    height: auto;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    z-index: 10;
}

// Hero

.hero--sector {
    position: relative;
    z-index: 0;
    padding: 30rem 0 4rem 0;
    margin-top: -150px;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
        height: 75vh;
        margin-top: -90px;
    }
    @include media-breakpoint-up(md) {
        max-height: 840px;
        min-height: 840px;
    }
    @include media-breakpoint-up(lg) {
        display: flex;
    }
    @include media-breakpoint-down(lg) {
        display: flex;
        padding: 0 0 3rem 0;
    }
    @include media-breakpoint-up(xl) {
        margin-top: -150px;
    }
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 213px;
        left: 0px;
        top: 0px;
        z-index: 1;
        background: linear-gradient(180deg, #000000 0%, rgba(21, 21, 21, 0) 100%);
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 454px;
        left: 0px;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .container {
        position: relative;
        z-index: 10;
        margin-top: auto;
    }
}

.hero--sector__image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        @include media-breakpoint-down(sm) {
            object-position: 30%;
        }
    }
    @include media-breakpoint-down(sm) {
        height: 75vh;
    }
    @include media-breakpoint-up (md) {
        max-height: 840px;
        min-height: 840px;
    }
    @include media-breakpoint-up(lg) {
        animation: zoomies 15s ease-in-out infinite alternate;
    }
}

.hero--sector__title {
    color: #fff;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
        display: none;
    }
    @include media-breakpoint-up(xl) {
        @include fontSize(96px);
    }
}