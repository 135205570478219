.breadcrumb-container {
	display: flex;
}
.breadcrumb-button {
	background: $black;
	padding: 1.25rem 2rem;
	color: $white;
	border-radius: 4px;
	transition: all .3s ease-in-out;
	&:hover {
		background: $primary;
		color: $white;
		svg {
			transform: translateX(-.5rem);
		}
	}
	svg {
		transition: all .3s ease-in-out;
	}
	span {
		margin-left: 1rem;
	}
}

.single-profile__main {
	width: 100%;
	max-width: 894px;
	margin: 0 auto;
	.text-large, .lead {
		max-width: 894px;
	}
	p, ul, ol, h2, h3, h4, h5 {
		max-width: 640px;
	}
}

.single-profile-wrapper {
	position: relative;
	&:before {
		z-index: -1;
		content: "";
		width: 100%;
		height: 100%;
		max-width: 1430px;
		max-height: 1266px;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml,%3Csvg width='1430' height='1266' viewBox='0 0 1430 1266' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M809.703 0.0152304C709.559 -1.25953 627.293 77.6483 625.997 176.187C625.997 176.57 625.997 176.952 625.997 177.207V349.428C625.35 457.527 535.699 544.466 425.839 543.829C425.58 543.829 425.191 543.829 424.932 543.829H183.705C83.5613 542.554 1.29552 621.462 0 720.001C0 720.511 0 721.148 0 721.658V1088.28C0.388657 1186.82 82.0067 1266.36 182.151 1265.98C182.669 1265.98 183.187 1265.98 183.705 1265.98H620.168C720.312 1267.38 802.577 1188.48 804.003 1089.94C804.003 1089.43 804.003 1088.79 804.003 1088.28V916.569C804.65 808.469 894.301 721.531 1004.16 722.168C1004.42 722.168 1004.81 722.168 1005.07 722.168H1246.29C1346.44 723.443 1428.7 644.535 1430 545.996C1430 545.486 1430 544.849 1430 544.339V177.207C1429.22 78.6681 1347.48 -0.749627 1247.33 0.0152304C1246.94 0.0152304 1246.68 0.0152304 1246.29 0.0152304H809.703Z' fill='%23F7F8FC'/%3E%3C/svg%3E%0A");
	}
}

.single-profile__intro {
	max-width: 894px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 4rem;
	@include media-breakpoint-down(sm) {
		flex-flow: column;
		align-items: flex-start;
	}
	@include media-breakpoint-down(lg) {
		padding-top: 2rem;
	}
}

.single-profile__image {
	max-width: 620px;
	overflow: hidden;
	border-radius: 1rem;
	position: relative;
	@include media-breakpoint-down(sm) {
		margin-top: 2rem;
	}
	@include media-breakpoint-up(md) {
		margin-left: auto;
	}
}

.single-profile__details {
	@include media-breakpoint-up(md) {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 9;
	}
}

.single-profile__name {
	margin-bottom: 0;
	max-width: 621px;
	font-size: 3.75rem;
	@include media-breakpoint-down(sm) {
		font-size: 2.25rem;
	}
}

.single-profile__position {
	text-transform: uppercase;
	color: $primary;
	font-weight: $fw-semi;
}
.single-profile__company {
	color: $black;
	font-weight: $fw-semi;
	letter-spacing: -0.05em;
	@include fontSize(16px);
}

.single-profile__company + .single-profile__company {
	padding-top: 1rem;
	margin-top: 1rem;
	border-top: 1px solid $grey-light;
}

.single-profile__share {
	display: flex;
	flex-flow: column wrap;
	position: absolute;
	right: 2rem;
	top: 2rem;
	list-style: none;
	padding-left: 0;
	z-index: 9;
	li {
		border-radius: 1rem;
		padding-left: 0;
		display: flex;
		padding: 0;
		margin-bottom: .5rem;
		&:before {
			display: none;
		}
	}
	li a {
		display: flex;
		width: 48px;
		height: 48px;
		border-radius: 1rem;
		justify-content: center;
		align-items: center;
		transition: .3s ease-in-out;
	}
}

.single-profile__share__linkedin {
	background: $white;
	border: 1px solid #dcdcdc;
	transition: .3s ease-in-out;
	&:hover {
		background: $black;
		border: 1px solid $black;
		a {
			color: $white;
		}
	}
	a {
		color: #2B2E33;
	}
}

.single-profile__share__email {
	background: $primary;
	transition: .3s ease-in-out;
	&:hover {
		background: $black;
	}
	a {
		color: $white;
	}
}