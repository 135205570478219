.contact-tabs {
    @include media-breakpoint-up(lg) {
        .footer & {
            max-width: 552px;
        }
        .contact-wrapper & {
            
        }
    }
}

.contact-tabs__details__icon {
    color: $white;
    display: inline-block;
    margin-right: .5rem;
}

.contact-tabs__tabs {
    display: flex;
}
.contact-tabs__tabs__item, .footer__contact-tabs__tabs__item {
    background: #535354;
    border: none;
    color: $white;
    padding: 1rem 2rem;
    transition: all .2s ease-in-out;
    font-weight: $fw-semi;
    @include media-breakpoint-down(sm) {
        padding: .9rem;
    }
    @include media-breakpoint-between(md, lg) {
        padding: 1rem .8rem;
        @include fontSize(15px);
    }
    &.active {
        background: #2A2A2A;
        &:hover {
            background: #2A2A2A;
        }
        .contact-wrapper & {
            background: #151515;
        }
    }
    &:first-of-type {
        border-top-left-radius: .5rem;
    }
    &:last-of-type {
        border-top-right-radius: .5rem;
    }
    &:hover {
        background: $primary;
    }
}

.contact-tabs__inner, .footer__contact-tabs__inner {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    background: #2A2A2A;
    padding: 2rem 1.5rem 1.5rem;
    border-radius: .5rem;
    border-top-left-radius: 0;
    .contact-wrapper & {
        background: #151515;
    }
    @include media-breakpoint-down(sm) {
        flex-flow: column;
    }
    .contact-wrapper & {
        @include media-breakpoint-down(md) {
            flex-flow: column;
        }
    }
}

.contact-tabs__address {
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
    @include media-breakpoint-up(xl) {
        width: 50%;
    }
    .btn {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        min-width: unset;
        width: 100%;
        max-width: 304px;
        display: block;
        @include buttonIconPin;
    }
    .contact-wrapper & {
        color: $white;
    }
}

.contact-tabs__details {
    list-style: none;
    margin-bottom: 0;
    .contact-wrapper & {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    li {
        padding-left: 0;
        &:before {
            display: none;
        }
        a {
            color: $white;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
                color: $primary;
            }
        }
        a.btn {
            width: 100%;
            text-decoration: none;
            margin-top: 1rem;
        }
    }
}

.contact-tabs__heading {
    @include media-breakpoint-down(md) {
        padding-top: 2rem;
        margin-bottom: 1.5rem;
        @include fontSize(24px);
    }
   @include media-breakpoint-up(lg) {
        padding-top: 2.5rem;
        margin-bottom: 2rem;
        @include fontSize(40px);
   }
}

.contact-tabs__text {
    margin-top: 2rem;
    @include media-breakpoint-up(lg) {
        padding-right: 2rem;
    }
}