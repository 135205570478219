.contact-bg {
    position: relative;
    @include media-breakpoint-up(lg) {
        &:before {
            position: absolute;
            left: 50%;
            top: -100px;
            transform: translateX(-50%);
            content: "";
            width: 1107px;
            height: 999px;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='1107' height='999' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M626.812.012c-77.524-1.006-141.208 61.26-142.211 139.017v136.704c-.502 85.302-69.902 153.905-154.948 153.402H142.211C64.687 428.129 1.003 490.395 0 568.152v290.609c.3 77.757 63.483 140.526 141.008 140.224h339.08c77.524 1.105 141.208-61.159 142.311-138.916V723.264c.502-85.301 69.902-153.905 154.948-153.402h187.442C1042.31 570.868 1106 508.602 1107 430.845V139.834C1106.4 62.077 1043.12-.592 965.591.012H626.812z' fill='%23F7F8FC'/%3E%3C/svg%3E");
        }
    }
}

// Addresses

// Footer addresses

.contact__addresses {
	display: flex;
	flex-flow: column wrap;
	gap: 1.5rem;
    @include media-breakpoint-down(md) {
        padding-top: 3rem;
    }
    @include media-breakpoint-between(sm, md) {
        flex-flow: row wrap;
    }
    @include media-breakpoint-up(xl) {
        flex-flow: row wrap;
    }
}
.contact__addresses__item {
	// flex: 1;
	address {
		@include fontSize(15px);
	}
    @include media-breakpoint-between(sm, md) {
        flex-basis: 48%;
    }
    @include media-breakpoint-up(xl) {
        flex-basis: calc(50% - 1.5rem);
    }
}
.contact__addresses__item__directions {
	display: flex;
	align-items: center;
	gap: .5rem;
	margin-bottom: .5rem;
	h3 {
		margin-bottom: 0;
		@include fontSize(20px);
	}
	.btn {
		font-size: 0.625rem;
		line-height: 1.5rem;
		padding: 0 .5rem;
		text-transform: uppercase;
		border-radius: 24px;
		border: 1px solid transparent;
		&:hover {
			color: $black;
			border: 1px solid $orange;
			background: transparent;
		}
	}
}