.client-logos {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    max-width: 1388px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    grid-template-rows: auto;
    margin-top: 4rem;
    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(2,1fr);
    }

    .testimonials-wrapper & {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        max-width: 1388px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        grid-template-rows: auto;
        margin-top: 0;
        @include media-breakpoint-down(md) {
            display: none;
            grid-template-columns: repeat(2,1fr);
        }
    }
}


.client-logos__item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include media-breakpoint-down(sm) {
        padding: 2rem;
    }
    @include media-breakpoint-only(md) {
        padding: 2rem 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        border-right: 1px solid $grey-light;
        padding: 3rem 3rem;

        &:nth-of-type(-n+4) {
            &:after{
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom: solid 1px $grey-light;
            }
        }
    
        &:nth-of-type(5), &:nth-of-type(10) {
            border-right: none;
        }
    }

    .testimonials-wrapper & {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include media-breakpoint-down(sm) {
            padding: 2rem;
        }
        @include media-breakpoint-only(md) {
            padding: 2rem 1rem;
        }
        @include media-breakpoint-up(lg) {
            border-right: 1px solid $grey-light;
            padding: 3rem 1rem;
    
            &:nth-of-type(-n+8) {
                &:after{
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-bottom: solid 1px $grey-light;
                }
            }
        
            &:nth-of-type(4), &:nth-of-type(8), &:nth-of-type(12) {
                border-right: none;
            }
        }
    }
}